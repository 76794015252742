.details-page {
  background: #151515;
}

.details-page .bottom-inner-image {
  position: absolute;
  bottom: 0;
  background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #ffffff 100%);
  width: 100%;
  min-height: 395px;
}

.white-bg {
  background: #ffffff;
}

.apply-padding {
  padding-top: 120px;
}

.remove-padding {
  padding-top: 80px;
}

.customize-btn {
  border: none;
  width: auto;
  height: 40px;
  border-radius: 4px;
  cursor: pointer;
  color: white;
  padding: 0 10px;
  background: rgba(46, 139, 152, 1);
}

.details-gallery {
  overflow: hidden;
  margin-bottom: 10px;
  position: relative;
  z-index: 2;
}
.intenary-title {
  color: #000;
  font-size: 26px;
}

.room-image-div {
  width: 160px;
}
.action-btns {
  display: flex;
  align-items: center;
  gap: 24px;
  span {
    display: flex;
    align-items: center;
    gap: 3px;
  }
}

.details-gallery .left {
  float: left;
  width: 700px;
  margin-right: 20px;
}

.details-gallery .right {
  float: left;
  width: calc(100% - 720px);
}

.details-gallery .left .image,
.details-gallery .right .image {
  position: relative;
  border-radius: 10px;
  overflow: hidden;
  background: rgba(0, 0, 0, 0.4);
}

.calender-holder {
  background-color: #000;
  border-radius: 5px;
}

.details-gallery .right .image {
  margin-bottom: 15px;
}

.details-gallery .left .image span,
.details-gallery .right .image span.view-all {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.view-all-mobile-view {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-weight: 700;
  font-size: 30px;
  line-height: 36px;
  color: #ffffff;
  cursor: pointer;
  z-index: 2;
}

.details-gallery .left .image span.play {
  background: rgba(0, 0, 0, 0.5);
  border-radius: 200px;
  overflow: hidden;
  width: 76px;
  height: 76px;
  cursor: pointer;
}

.details-gallery .right .image span.view-all {
  font-weight: 700;
  // font-size: 30px;
  line-height: 36px;
  color: #ffffff;
  cursor: pointer;
  z-index: 2;
  font-size: 22px;
  background: linear-gradient(
    0deg,
    rgba(21, 21, 21, 0.6),
    rgba(21, 21, 21, 0.6)
  );
  border-radius: 10px;
  padding: 0px 10px;
  position: absolute;
  top: 0;
  right: 0;
  left: auto;
  transform: translate(-10px, 10px);
}

.details-gallery .right .image span.layer {
  background: linear-gradient(
    0deg,
    rgba(21, 21, 21, 0.6),
    rgba(21, 21, 21, 0.6)
  );
  border-radius: 10px;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 1;
}

.layer-mobile-view {
  background: linear-gradient(
    0deg,
    rgba(21, 21, 21, 0.6),
    rgba(21, 21, 21, 0.6)
  );
  border-radius: 10px;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 1;
}

.no-margin {
  margin: 0 !important;
}

.details-content-blk {
  overflow: hidden;
  margin-bottom: 40px;
  min-height: 375px;
}

.details-content-left {
  // float: left;
  // width: 700px;
  // margin-right: 20px;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: #111111;
}

.details-content-left .content .head {
  font-weight: 600;
  font-size: 25px;
  line-height: 38px;
  margin-top: 20px;
  overflow: hidden;
  color: #111111;
}

.details-content-left .content .head div span.share,
.details-content-left .content .head div span.print {
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  cursor: pointer;
}

.details-content-left .content .head div span.share img,
.details-content-left .content .head div span.print img {
  margin-right: 5px;
  vertical-align: middle;
  display: inline-block;
}

.details-content-left .content ul {
  margin: 10px 0 0 0;
  padding: 0 0 0 18px;
}

.details-content-left .content ul li {
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  margin-bottom: 10px;
}

.details-content-left .content ul li a {
  color: #2e8b98;
}

.details-content-left .content ul.inline,
.details-content-left .content ul.inline-block {
  padding: 0;
}

.details-content-left .content ul.inline li,
.details-content-left .content ul.inline-block li {
  display: inline-block;
  vertical-align: middle;
  // width: 24%;
  margin-bottom: 15px;
  margin-right: 15px;
}

.details-content-left .content ul.inline-block li {
  width: inherit;
  margin-right: 25px;
}

.details-content-left .content ul.inline li span,
.details-content-left .content ul.inline-block li span {
  display: inline-block;
  vertical-align: middle;
  margin-right: 10px;
}

.holiday-buttton {
  display: flex;
  justify-content: space-between;
}

.details-content-left .content .image {
  border-radius: 10px;
  overflow: hidden;
  margin-bottom: 10px;
  position: relative;
}

.details-content-left .content .image .nav-left {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  left: 0;
  top: 0;
  width: 60px;
  height: 100%;
  background: linear-gradient(
    90deg,
    rgba(0, 0, 0, 0.4) 0%,
    rgba(40, 40, 40, 0) 100%
  );
  border-radius: 10px 0px 0px 10px;

  .left-active,
  .left-disabled {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 21px;
    height: 37px;
    transform: translate(-50%, -50%);
    background: url("../../assets/images/enable-left.png") no-repeat center
      center / cover;
    cursor: pointer;
  }

  .left-disabled {
    background: url("../../assets/images/disable-left.png") no-repeat center
      center / cover;
    // top: 46%;
    // left: 41%;
  }
}

.details-content-left .content .image .nav-right {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  right: 0;
  top: 0;
  height: 100%;
  width: 60px;
  background: linear-gradient(
    90deg,
    rgba(40, 40, 40, 0) 0%,
    rgba(0, 0, 0, 0.4) 100%
  );
  border-radius: 0px 10px 10px 0px;

  .right-active,
  .right-disabled {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 21px;
    height: 37px;
    transform: translate(-50%, -50%);
    background: url("../../assets/images/enable-right.png") no-repeat center
      center / cover;
    cursor: pointer;
  }

  .right-disabled {
    background: url("../../assets/images/disable-right.png") no-repeat center
      center / cover;
    // top: 46%;
    // left: 41%;
  }
}

.details-content-left .content p,
.details-content-left .content span {
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  // display: inline-block;
  text-align: justify;
}
.act-btn-txt {
  display: block;
}

.details-content-left .content p span {
  display: block;
  font-weight: 700;
}

.details-content-left .content .splitter {
  background: #969696;
  height: 2px;
  margin-top: 0px;
}

.details-content-left .content .tabs {
  width: 100%;
  overflow: auto;
  margin-bottom: 10px;
  scrollbar-width: thin;
}

.details-content-left .content .tabs .tab {
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  text-align: center;
  color: #2e8b98;
  padding: 12px 20px;
  margin: 10px 0 5px 0;
  display: inline-block;
  vertical-align: middle;
  cursor: pointer;
  background: #e3e3e3;
  border: 1px solid #2e8b98;
  border-radius: 0px;
  border-left: 0 none;
}

.details-content-left .content .tabs .tab:first-child {
  border-radius: 6px 0 0 6px;
  border-left: 1px solid #2e8b98;
}

.details-content-left .content .tabs .tab:last-child {
  border-radius: 0 6px 6px 0;
}

.details-content-left .content .tabs .tab.active {
  border: 1px solid #2e8b98;
  color: #ffffff;
  background: #2e8b98;
  border-radius: 6px;
  padding-top: 20px;
  padding-bottom: 20px;
}

.details-content-left .content .itinerary-blk {
  margin-top: 18px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.details-content-left .content .itinerary-blk .itinerary {
  background: #f6f6f6;
  border: 1px solid rgba(0, 0, 0, 0.3);
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  // margin-bottom: 20px;
  overflow: hidden;
  border-radius: 10px;
}

.details-content-left .content .itinerary-blk .itinerary .itinerary-image {
  border-radius: 10px 0px 0px 10px;
  width: 400px;
  // margin-right: 20px; //35px;
  float: left;
  min-height: 255px;
  position: relative;
  background: rgba(0, 0, 0, 0);
}

.details-content-left
  .content
  .itinerary-blk
  .itinerary
  .itinerary-image
  div.image-container {
  position: absolute;
  top: 50%;
  width: 100%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.details-content-left .content .itinerary-blk .itinerary .itinerary-content {
  width: calc(100% - 370px);
  float: left;
  flex-grow: 1;
  // align-self: flex-start;
  padding: 10px 20px;
}

.itinerary .itinerary-content .sub-head {
  font-weight: 700;
  font-size: 20px;
  // line-height: 19px;
  color: #000;
  // margin-bottom: 12px;
}

.itinerary .itinerary-content .day-itinerary {
  margin-top: 0;
}

.itinerary .itinerary-content .head {
  font-weight: 500;
  font-size: 19px;
  line-height: 25px;
  margin-bottom: 5px;
  margin-top: 0;
  width: 100%; //#new
}

.itinerary .itinerary-content p.note {
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
}

.itinerary .itinerary-content p.note.btn {
  cursor: pointer;
  color: #2e8b98;
  // margin-left: 5px;
}

.itinerary .itinerary-content p.note.btn.show-less {
  margin-left: 5px;
}

.details-content-right {
  color: #111111;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  // width: calc(100% - 720px);
  // float: left;
  background: #f7f6f6;
  border-radius: 10px;
  float: right;
  padding: 20px;
  width: 380px;
}

.details-content-right .head {
  font-weight: 600;
  font-size: 25px;
  line-height: 38px;
  // margin-bottom: 30px;
}

.details-content-right .form-fields {
  overflow: inherit;
  min-height: 90px;
  margin: 0 -10px;
}

.details-content-right .form-fields.btn,
.details-content-right .form-fields.error {
  min-height: inherit;
  clear: both;
  margin: 10px 0 0 0;
}

.details-content-right .form-fields.error p {
  text-align: left;
}

.details-content-right .form-fields .w50 {
  float: left;
  width: calc(50% - 20px);
  position: relative;
  margin: 0 10px;
  margin-top: 20px;
}

.details-content-right .form-fields label {
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  margin-bottom: 10px;
  display: block;
  position: relative;
}

.details-content-right .form-fields label span {
  position: absolute;
  right: 0;
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  text-align: right;
}

.details-content-right .form-fields input,
.details-content-right .form-fields select {
  padding: 12px 40px 12px 20px;
  background: #000;
  box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.16);
  border-radius: 6px;
  font-weight: 600;
  font-size: 13px;
  line-height: 16px;
  color: #ffffff;
  width: calc(100% - 62px);
  border: 1px solid #000;
}

.details-content-right .form-fields input[type="text"]:disabled {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background: #000;
  opacity: 1;
}

.details-content-right .form-fields select {
  width: 100%;
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  background-image: url("data:image/svg+xml;utf8,<svg fill='white' height='24' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg'><path d='M7 10l5 5 5-5z'/><path d='M0 0h24v24H0z' fill='none'/></svg>");
  background-repeat: no-repeat;
  background-position-x: 95%;
  background-position-y: 10px;
}

.details-content-right .form-fields span.icon {
  position: absolute;
  right: 15px;
  top: 36px;
  cursor: pointer;
}

.details-content-right .form-fields .green-btn {
  background: #ffffff;
  border: 1px solid #2e8b98;
  border-radius: 10px;
  padding: 13px 20px;
  text-align: center;
  color: #000000;
  width: calc(100% - 40px);
  text-transform: capitalize;
  cursor: pointer;
  // width: calc(100% - 60px);
  margin-top: 25px;
}

.details-content-right .form-fields .green-btn.active {
  background: #2e8b98;
  width: calc(100% - 40px);
  color: #ffffff;
}

.details-content-right .form-fields .normal-btn {
  background: #ffffff;
  border: 1px solid rgba(0, 0, 0, 0.5);
  border-radius: 10px;
  padding: 13px 20px;
  text-align: center;
  color: #111111;
  cursor: pointer;
  width: calc(100% - 40px);
  margin-top: 15px;
}

.details-content-right .total-amount {
  overflow: hidden;
  padding: 15px 0;
  width: calc(100% - 0px);
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
}

.details-content-right .total-amount .left {
  float: left;
}

.details-content-right .total-amount .left.w70 {
  width: 70%;
}

.details-content-right .total-amount .right {
  float: right;
}

.details-content-right .total-amount.border-bottom {
  border-bottom: 1px solid #969696;
}

.details-content-right .total-amount .left.bold,
.details-content-right .total-amount .right.bold {
  font-weight: 700;
}

.details-content-right .total-amount .left.extra-bold,
.details-content-right .total-amount .right.extra-bold {
  font-weight: 700;
  font-size: 18px;
  line-height: 27px;
}

.details-content-right p {
  font-weight: 600;
  font-size: 14px;
  line-height: 21px;
  text-align: center;
}

.details-content-right p a {
  color: #2e8b98;
}

.popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background: rgba(40, 40, 40, 0.5);
  z-index: 300;
}

.gallery-popup {
  position: absolute;
  top: 51%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: #000000;
  padding: 20px;
  color: #ffffff;
  width: 92%;
  height: 98%;
}

.gallery-popup .sub-head {
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  position: relative;
  margin-bottom: 20px;
}

.gallery-popup .sub-head span.head {
  width: 80%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
}

.gallery-popup .sub-head span.close {
  position: absolute;
  right: 0;
  top: 0;
}

.gallery-popup .sub-head span a {
  color: #ffffff;
  cursor: pointer;
}

.gallery-popup .head {
  font-weight: 600;
  font-size: 40px;
  line-height: 48px;
  margin-bottom: 30px;
}

.gallery-popup .image-big {
  width: calc(65% - 10px);
  float: left;
  margin-left: 10px;
  height: 85vh;
  position: relative;
}

.gallery-popup .image-big .image-container {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
}

.gallery-popup .image-big span.video-play {
  width: 70px;
  height: 70px;
  border-radius: 70px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: rgba(0, 0, 0, 0.5);
  cursor: pointer;
}

.gallery-popup .images-small-blk {
  overflow: auto;
  float: left;
  width: 35%;
  height: 85vh;
}

.gallery-popup .images-small-blk ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

.gallery-popup .images-small-blk ul li {
  display: inline-block;
  vertical-align: middle;
  // width: 22%;
  margin: 0 5px;
  border: 4px solid transparent;
  position: relative;
}

.gallery-popup .images-small-blk ul li.active {
  border: 4px solid #ffffff;
}

.blue-bg {
  background-color: #d2f6ff;
  // display: flex;
  // flex-direction: column;
  // justify-content: center;
  // gap: 35px;
  // align-items: center;
  padding: 24px 70px;
}

.pdp-stays {
  display: flex;
  flex-direction: column;
  gap: 30px;
  width: 100%;
  max-width: 1140px;
  margin: auto;
}

.sticky-header {
  position: fixed;
  top: 80px;
  z-index: 10;
  width: 100%;
}

.non-sticky-header {
  position: fixed;
  top: 0px;
  z-index: 20;
  width: 100%;
}

@media screen and (max-width: 1023px) {
  .non-sticky-header {
    top: 80px;
  }
  .remove-padding {
    padding-top: 100px;
  }
  .apply-padding {
    padding-top: 160px;
  }
}

@media screen and (min-width: 768px) and (max-width: 1023px) {
  // .pdp-stays {
  //   width: 100%;
  //   max-width: 540px;
  // }
  .blue-bg {
    padding: 24px 25px;
  }
  .details-content-right .form-fields input {
    width: calc(100% - 50px);
  }
}

@media screen and (max-width: 767px) {
  .blue-bg {
    padding: 24px 25px;
  }
  .room-image-div {
    width: 100%;
    height: 170px;
  }
  .details-content-right .form-fields input {
    width: calc(100% - 44px);
  }
}

@media screen and (min-width: 375px) and(max-width:424px) {
  .details-content-right .form-fields input {
    width: calc(100% - 62px);
  }
}

@media screen and (max-width: 374px) {
  .details-content-right .form-fields input {
    width: calc(100% - 64px);
  }
}

.gallery-popup .images-small-blk ul li span.active-overlay {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  transform: translate(-50%, -50%);
  position: absolute;
  display: none;
}

.gallery-popup .images-small-blk ul li.active span.active-overlay {
  display: block;
}

.image-gallery-main {
  min-height: 400px;
  max-height: 400px;
  width: 100%;
  border-radius: 10px;
  object-fit: cover;
  display: block;
}

.image-gallery-sub {
  min-height: 192px;
  max-height: 192px;
  width: 100%;
  border-radius: 10px;
  object-fit: cover;
  display: block;
}

.image-gallery-popup {
  width: calc(33.33% - 18px);
  float: left;
  margin: 5px;
  border: 4px solid transparent;
}

.image-gallery-popup.active {
  border: 4px solid #ccc;
}

.image-gallery-popup img {
  min-height: 85px;
  max-height: 85px;
  width: 100%;
}

.gallery-popup .image-big img {
  // min-height: 250px;
  // max-height: 250px;
  // width: 100%;
}

.bin-image {
  display: inline-block;
  vertical-align: text-bottom;
  width: 24px;
  margin-left: 10px;
}

// .room-type-image {
//     height: 350px !important;
//     width: 100%;
//     display: block;
// }

.inclusion-view-more {
  text-align: left;
  margin-left: 5px;
  font-weight: 700 !important;
  font-size: 14px;
  color: #2e8b98;
  cursor: pointer;
}
.gallery-popup .image-big .image-container .right-arrow {
  position: absolute;
  top: 50%;
  right: 0;
  width: 21px;
  height: 37px;
  transform: translate(-50%, 0);
  background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYEAQAAAAa7ikwAAAABGdBTUEAALGPC/xhBQAAACBjSFJNAAB6JgAAgIQAAPoAAACA6AAAdTAAAOpgAAA6mAAAF3CculE8AAAAAmJLR0QAAKqNIzIAAAAJcEhZcwAAAGAAAABgAPBrQs8AAAAHdElNRQfmCgcUEBZ+l96LAAAAoklEQVRIx7XWsRGAIAwF0MCCOoFTeHaWNmYDLV2PcwS/hXdWENAkv+X4L1WAqBCg74HzBOaZPIJr3/FmWZwBABezL2CNZAFLpAhYISLwAYm/Jwjj2IL8BxoRHdCA6IEKYgO8yDD4AUREBPgBYA7xOHwAMIc4TbkjPSCU64FKuXy3uirWVTecuOyU5SJgUV4ErMqzgGX5A2yb76OPrgNS0n5bbqqUV0akZO6EAAAAJXRFWHRkYXRlOmNyZWF0ZQAyMDIyLTEwLTA3VDIwOjE2OjIyKzAwOjAwQhYRPwAAACV0RVh0ZGF0ZTptb2RpZnkAMjAyMi0xMC0wN1QyMDoxNjoyMiswMDowMDNLqYMAAAAodEVYdGRhdGU6dGltZXN0YW1wADIwMjItMTAtMDdUMjA6MTY6MjIrMDA6MDBkXohcAAAAAElFTkSuQmCC)
    no-repeat center center/cover;
  cursor: pointer;
}

.image-big .image-container .right-arrow-icon {
  position: absolute;
  // top: 50%;
  bottom: 25%;
  right: 0;
  width: 21px;
  height: 37px;
  transform: translate(-50%, 0);
  background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYEAQAAAAa7ikwAAAABGdBTUEAALGPC/xhBQAAACBjSFJNAAB6JgAAgIQAAPoAAACA6AAAdTAAAOpgAAA6mAAAF3CculE8AAAAAmJLR0QAAKqNIzIAAAAJcEhZcwAAAGAAAABgAPBrQs8AAAAHdElNRQfmCgcUEBZ+l96LAAAAoklEQVRIx7XWsRGAIAwF0MCCOoFTeHaWNmYDLV2PcwS/hXdWENAkv+X4L1WAqBCg74HzBOaZPIJr3/FmWZwBABezL2CNZAFLpAhYISLwAYm/Jwjj2IL8BxoRHdCA6IEKYgO8yDD4AUREBPgBYA7xOHwAMIc4TbkjPSCU64FKuXy3uirWVTecuOyU5SJgUV4ErMqzgGX5A2yb76OPrgNS0n5bbqqUV0akZO6EAAAAJXRFWHRkYXRlOmNyZWF0ZQAyMDIyLTEwLTA3VDIwOjE2OjIyKzAwOjAwQhYRPwAAACV0RVh0ZGF0ZTptb2RpZnkAMjAyMi0xMC0wN1QyMDoxNjoyMiswMDowMDNLqYMAAAAodEVYdGRhdGU6dGltZXN0YW1wADIwMjItMTAtMDdUMjA6MTY6MjIrMDA6MDBkXohcAAAAAElFTkSuQmCC)
    no-repeat center center/cover;
  cursor: pointer;
}

.gallery-popup .image-big .image-container .left-arrow {
  position: absolute;
  top: 50%;
  left: 15px;
  width: 21px;
  height: 37px;
  transform: translate(-50%, 0);
  background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYEAQAAAAa7ikwAAAABGdBTUEAALGPC/xhBQAAACBjSFJNAAB6JgAAgIQAAPoAAACA6AAAdTAAAOpgAAA6mAAAF3CculE8AAAAAmJLR0QAAKqNIzIAAAAJcEhZcwAAAGAAAABgAPBrQs8AAAAHdElNRQfmCgcUECHGKnuEAAAAs0lEQVRIx7WWWw6EIAxFb2dluh2jcR98SnSTxi1w52uGxIlAW6af0JwT+kgAnEGKMIVAXhc5jl7eL5z7zk+kEO45Lw8c2DZgmkp5JkGGz3MtVy3QwNUCLVwlsMCbBVZ4k8ADrwq88KKgB7z8Ai6LF14tUY94FkiMQIx/E4iQwLp6JcUS9ZBUe+CVNDXZI2meIqtENaYWiXoPtBLTomkk5k3OkuPIh6SV9xjfb0s6T3IY7vdvAqB2JiOscXkAAAAldEVYdGRhdGU6Y3JlYXRlADIwMjItMTAtMDdUMjA6MTY6MzMrMDA6MDAoyxoVAAAAJXRFWHRkYXRlOm1vZGlmeQAyMDIyLTEwLTA3VDIwOjE2OjMzKzAwOjAwWZaiqQAAACh0RVh0ZGF0ZTp0aW1lc3RhbXAAMjAyMi0xMC0wN1QyMDoxNjozMyswMDowMA6Dg3YAAAAASUVORK5CYII=)
    no-repeat center center/cover;
  cursor: pointer;
}

.image-big .image-container .left-arrow-icon {
  position: absolute;
  // top: 50%;
  bottom: 25%;
  left: 15px;
  width: 21px;
  height: 37px;
  transform: translate(-50%, 0);
  background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYEAQAAAAa7ikwAAAABGdBTUEAALGPC/xhBQAAACBjSFJNAAB6JgAAgIQAAPoAAACA6AAAdTAAAOpgAAA6mAAAF3CculE8AAAAAmJLR0QAAKqNIzIAAAAJcEhZcwAAAGAAAABgAPBrQs8AAAAHdElNRQfmCgcUECHGKnuEAAAAs0lEQVRIx7WWWw6EIAxFb2dluh2jcR98SnSTxi1w52uGxIlAW6af0JwT+kgAnEGKMIVAXhc5jl7eL5z7zk+kEO45Lw8c2DZgmkp5JkGGz3MtVy3QwNUCLVwlsMCbBVZ4k8ADrwq88KKgB7z8Ai6LF14tUY94FkiMQIx/E4iQwLp6JcUS9ZBUe+CVNDXZI2meIqtENaYWiXoPtBLTomkk5k3OkuPIh6SV9xjfb0s6T3IY7vdvAqB2JiOscXkAAAAldEVYdGRhdGU6Y3JlYXRlADIwMjItMTAtMDdUMjA6MTY6MzMrMDA6MDAoyxoVAAAAJXRFWHRkYXRlOm1vZGlmeQAyMDIyLTEwLTA3VDIwOjE2OjMzKzAwOjAwWZaiqQAAACh0RVh0ZGF0ZTp0aW1lc3RhbXAAMjAyMi0xMC0wN1QyMDoxNjozMyswMDowMA6Dg3YAAAAASUVORK5CYII=)
    no-repeat center center/cover;
  cursor: pointer;
}

.gallery-popup .image-big .image-counter {
  position: absolute;
  right: 0;
  bottom: 0;
  background: #ccc;
  padding: 5px;
  color: #000;
  font-weight: 700;
}

p.text-ellipsis {
  overflow: hidden;
  // text-overflow: ellipsis;
  // display: -webkit-box !important;
  // -webkit-line-clamp: 3;
  // -webkit-box-orient: vertical;
}

.booknow-icon {
  position: fixed;
  top: 95%;
  left: 25%; //45%;
  transform: translate(0, -50%);
  z-index: 2;
}

.booknow-icon ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

.booknow-icon ul li {
  margin-bottom: 12px;
  font-size: 1em;
  width: 45px;
}

.booknow-icon ul li img {
  position: relative;
  cursor: pointer;
}

.booknow-icon ul li:last-child {
  margin-bottom: 0;
}

.booknow-icon ul li span {
  display: none;
  position: absolute;
  right: 0;
  width: 110px;
  color: #fff;
  top: 0px;
  background: #314045;
  padding: 10px 60px 10px 20px;
  border-radius: 100px;
  line-height: 27px;
}

.booknow-icon ul li:hover span {
  display: inline-block;
}

.details-content-left .content .description {
  margin-top: 14px;
  font-weight: inherit;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  overflow: hidden;
  display: block;
  line-height: 17px;
  max-height: 34px;
  -webkit-box-orient: vertical;
}

.details-content-left .content .description p span,
.details-content-left .content .description p {
  margin-top: 0px;
  font-weight: 400;
}
// .details-content-left .content .description p  {
//     font-weight: inherit;
//     overflow: hidden;
//     text-overflow: ellipsis;
//     display: -webkit-box;
//     -webkit-line-clamp: 2;
//     line-clamp: 2;
//     -webkit-box-orient: vertical;
// }

.details-content-left .content .description-show-full-text {
  margin-top: 14px;
  font-weight: 400;
}

.details-content-left .content .description-show-full-text p span,
.details-content-left .content .description-show-full-text p {
  margin-top: 0px;
  font-weight: 400;
}

.details-content-left .content .day-itinerary p span,
.details-content-left .content .terms-and-conditions p span {
  font-weight: inherit;
}

.details-content-left .content .terms-and-conditions p {
  margin-top: 10px;
}

@media screen and (min-width: 100px) and (max-width: 767px) {
  .owl-theme .owl-nav {
    position: absolute;
    top: 50%;
    right: 0;
    margin: 0;
    display: flex;
    justify-content: space-between;
    width: 100%;
  }
  .itinerary .itinerary-content .sub-head {
    font-size: 15px;
  }
  .action-btns {
    span {
      .act-btn-txt {
        display: none;
      }
    }
  }
}

.main-banner {
    width: 100%;
    height: auto;
    position: relative;
    z-index: 1;
}

.main-banner .background-overlay{
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.3) 0%, rgba(0, 0, 0, 0) 103.75%);
}

.main-banner .images {
    position: relative;
}

.main-banner .images .background-overlay {
    border-radius: 0;
}

.main-banner .images img {
    display: block !important;
}

.main-banner .section {
    position: absolute;
    top: calc(50% + 40px);
    width: calc(100% - 60px);
    left: 50%;
    transform: translate(-50%, -50%);
}

.main-banner .left-text {
    float: left;
    width: 50%;
}

.main-banner .right-text,
.main-banner .right-video {
    float: right;
    width: 50%;
    text-align: right;
}

.main-banner .right-video {
    width: calc(50% - 4px);
    border: 2px solid #FFFFFF;
    box-shadow: 0px 18px 18px rgba(0, 0, 0, 0.4);
    border-radius: 10px;
}

.main-banner .left-text h1.head {
    font-size: 25px;
    line-height: 40px;
    font-weight: 500;
}

.main-banner p.normal-text {
    font-weight: 400;
    font-size: 15px;
    line-height: 20px;
    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    margin-top: 15px;
}

.main-banner p.limited {
    font-weight: 600;
    font-size: 12px;
    line-height: 18px;
    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    margin-top: 15px;
}

.main-banner p.limited img {
    display: inline-block !important;
    vertical-align: middle;
    margin-right: 10px;
}

.main-banner .timer ul {
    margin: 25px 0 0 0;
    padding: 0;
    list-style: none;
}

.main-banner .timer ul li {
    display: inline-block;
    vertical-align: middle;
    margin-right: 10px;
    text-align: center;
    position: relative;
}

.main-banner .timer ul li .count {
    background: #151515;
    border-radius: 5px;
    padding: 7px;
    font-size: 22px;
    line-height: 26px;
    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    font-weight: 600;
    display: inline-block;
    position: relative;
}

.main-banner .timer ul li .count .splitter {
    position: absolute;
    height: 1px;
    background: #000000;
    width: 100%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.main-banner .timer ul li .count-text {
    display: block;
    font-weight: 500;
    font-size: 12px;
    line-height: 14px;
    text-transform: uppercase;
    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    margin-top: 5px;
}

.main-banner .green-btn {
    margin-top: 30px;
}

.main-banner .green-btn a,
.main-banner .green-btn span {
    background: #2E8B98;
    border-radius: 6px;
    font-weight: 500;
    font-size: 12px;
    line-height: 24px;
    text-transform: uppercase;
    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    padding: 8px 75px;
    display: inline-block;
    color: #ffffff;
}

.main-banner .right-text h2.sub-head {
    font-weight: 600;
    font-size: 26px;
    line-height: 30px;
    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    width: 60%;
    margin: 30px 0 30px auto;
}

.main-banner .brown-btn a {
    background: rgba(104, 48, 48, 0.7);
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.35);
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    padding: 10px 20px;
    display: inline-block;
    color: #ffffff;
}

.loading {
    filter: blur(10px);
    clip-path: inset(0);
  }
  .loaded {
    filter: blur(0px);
    transition: filter 0.5s linear;
  }
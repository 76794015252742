html {
    min-height: 100%;
    position: relative;
}

input,
select,
textarea {
    font-family: 'Poppins', sans-serif;
    font-weight: 400;
}

html,
body {
    margin: 0;
    padding: 0;
    color: #ffffff;
    background: #1E1E1E;
    font-family: 'Poppins', sans-serif;
    font-size: 14px;
    line-height: 21px;
    font-weight: 400;
}

section {
    position: relative;
    padding: 90px 0;
}

article,
aside,
details,
figcaption,
figure,
footer,
header,
main,
menu,
nav,
section,
summary {
    display: block;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    color: #fff;
    margin: 0;
    line-height: 1.3;
    font-weight: 600;
}

p,
ul li {
    font-size: 1.1em;
    line-height: 1.5em;
    font-weight: 300;
    margin-bottom: 0;
}

a {
    position: relative;
    text-decoration: none;
    color: #333333;
}

a:hover {
    text-decoration: none;
}

:focus {
    outline: none !important;
    box-shadow: none !important;
    // border-color: #CDCDCD !important;
}

::-webkit-input-placeholder {
    /* Edge */
    color: #A5A5A5;
}

:-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: #A5A5A5;
}

::placeholder {
    color: #A5A5A5;
}

.transition,
a {
    -webkit-transition: all 0.4s ease-in-out;
    -moz-transition: all 0.4s ease-in-out;
    -o-transition: all 0.4s ease-in-out;
    -ms-transition: all 0.4s ease-in-out;
    transition: all 0.4s ease-in-out;
}

a,
a:focus {
    outline: 0 none !important;
}

.img-fluid {
    max-width: 100%;
    height: auto;
}

.owl-carousel .owl-item img {
    display: initial;
    width: auto;
}

.floating-icons {
    z-index: 2;
}

.details-content-left .content .itinerary-blk .itinerary .itinerary-image img {
    display: block;
    min-height: 255px;
    max-height: 255px;
    width: 100%;   
    object-fit: cover;     
}

.info-txt.view-more {
    color: #2E8B98;
}

/* Media Query */
@media screen and (min-width: 1024px) and (max-width: 1280px) {

    .details-content-left,
    .details-gallery .left {
        width: calc(60% - 20px);
    }

    .details-content-right,
    .details-gallery .right {
        width: calc(40% - 20px);
        padding: 10px;
    }

    .details-content-right .form-fields .w50 {
        margin: 20px 10px 0;
    }

    .details-content-right .form-fields .green-btn {
        margin: 25px 0px 0;
    }

    .details-content-right .total-amount {
        margin: 0 10px;
    }

    .details-gallery .right .image span.layer {
        width: 100%;
    }

    .details-content-left .content .itinerary-blk .itinerary .itinerary-content {
        padding: 15px 25px 15px 20px;
    }

    .header .menu {
        margin-left: 20px;
    }

    .header .search {
        display: block;
        margin: 14px 0px 14px 0;
    }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
    .main-banner .section {
        padding-top: 60px;
    }

    .filter-result-blk .content .title {
        font-size: 18px;
        line-height: 20px;
    }

    .filter-result-blk .content .offer-blk .left,
    .filter-result-blk .content .offer-blk .right {
        float: none;
    }

    .filter-result-blk .content .offer-blk .right {
        margin-top: 15px;
    }

    .filter-result-blk .content .offer-blk .right .book-btn {
        display: inline-block;
    }

    .filter-result-blk .content .sub-title {
        margin-top: 0;
    }

    .details-content-left .content .itinerary-blk .itinerary .itinerary-image {
        width: 240px;
        margin-right: 20px;
    }

    .details-content-left .content .itinerary-blk .itinerary .itinerary-content {
        width: calc(100% - 275px);
        padding: 15px 15px 15px 0;
    }

    .details-content-left .content .tabs .tab {
        margin: 10px 0;
    }

    .confirm-layout .left {
        width: 56%;
    }

    .confirm-layout .right {
        width: 37%;
    }

    .confirm-layout .left .room-image,
    .confirm-layout .left .room-content {
        width: auto;
        float: none;
    }

    .confirm-layout .left .room-image {
        height: 300px;
        overflow: hidden;
    }

    .confirm-layout .left .room-image .img,
    .confirm-layout .left .room-content .head {
        margin-bottom: 15px;
    }

    .confirm-layout .left .room-image .img {
        background: rgba(0, 0, 0, 0.4);
    }

    .confirm-layout .left .room-image .img img{ 
        object-fit: cover;
        height: 300px;
        width: 100%;
    }

    .my-account-blk .my-account-menu {
        width: auto;
        float: none;
        margin-right: 0;
    }

    .my-account-blk .my-account-menu ul li {
        display: inline-block;
        vertical-align: middle;
    }

    .my-account-blk .my-account-content {
        width: auto;
        float: none;
    }

    .my-account-content .profile-pic-blk {
        padding: 20px 0;
    }

    .my-account-content .profile-pic-blk .picture .pic,
    .my-account-content .profile-pic-blk .picture {
        width: 100px;
        height: 100px;
        border-radius: 100px;
    }

    .my-account-content .profile-pic-blk .picture .pic img {
        height: 100%;
    }

    .discover-international .left .green-btn a,
    .discover-international .right .green-btn a {
        padding: 8px 45px;
    }

    .deals-gallery.discover ul li {
        width: 31.5%;
    }

    .filter-result-blk .content .offer-blk {
        overflow: auto;
        position: relative;
        bottom: inherit;
        width: auto;
    }

    .filter-result-blk .content .offer-blk .left,
    .filter-result-blk .content .offer-blk .right {
        float: none;
        margin-top: 5px;
    }

    .testimonial-blk-left,
    .testimonial-blk-right {
        width: auto !important;
        float: none !important;
    }

    .testimonial-blk .user-testimonial .top-quotes,
    .testimonial-blk .user-testimonial .bottom-quotes {
        width: 17px !important;
        height: 11px !important;
    }

    .testimonial-blk .user-testimonial {
        font-size: 15px;
        line-height: 20px;
        padding: 15px;
        max-height: 200px;
        overflow: hidden;
    }

    .discover-international .left .offer,
    .discover-international .right .offer {
        font-size: 25px;
        line-height: 40px;
    }


}

@media screen and (min-width: 768px) and (max-width: 1023px) {
    .filter-result-blk .content .offer-blk .left {
        float: left;
    }

    .filter-result-blk .content .offer-blk .right {
        float: right;
        margin-top: 0;
    }

    .details-content-left,
    .details-gallery .left {
        width: auto;
        float: none;
        margin-right: 0;
    }

    .details-content-right,
    .details-gallery .right {
        width: auto;
        float: none;
        margin-top: 20px;
    }

    .details-content-right .form-fields .w50 {
        margin: 20px 10px 0;
    }

    .details-content-right .form-fields .green-btn {
        margin: 25px 10px 0;
    }

    .details-content-right .total-amount {
        margin: 0 10px;
    }

    .details-gallery .right .image span.layer {
        width: 100%;
    }
}

@media screen and (min-width: 768px) and (max-width: 1023px) {

    .header .menu,
    .header .search,
    .header .right-menu {
        display: none;
    }

    .header .logo {
        width: 85px;
        margin: 0 auto;
        float: none;
    }

    .main-banner .images {
        height: 40vh;
    }

    .main-banner .images img {
        height: 100%;
    }

    .about-section ul {
        text-align: left;
    }

    .about-section ul li {
        margin: 0 35px 30px 35px;
        width: 38%;
    }

    .discover-international {
        padding: 30px;
    }

    .discover-international .left {
        width: 100%;
        float: none;
    }

    .discover-international .left .discover-blk {
        text-align: center;
    }

    .discover-international .right {
        float: none;
    }

    .discover-international .right .discover-blk {
        background-size: 110% !important;
        background-position: center center !important;
        text-align: center;
        margin-top: 30px;
        border-radius: 10px;
    }

    .discover-international-section .head {
        text-align: center;
    }

    .discover-international .right .discover-blk .overlay {
        position: absolute;
        top: 50%;
        left: 50%;
        width: 100%;
        height: 100%;
        background: rgba(0, 0, 0, 0.5);
        transform: translate(-50%, -50%);
        border-radius: 10px;
    }

    .discover-international .left .heading,
    .discover-international .right .heading,
    .discover-international .left .offer,
    .discover-international .right .offer,
    .discover-international .left p,
    .discover-international .right p {
        position: relative;
    }

    .footer-section .footer-newsletter .input input {
        min-width: 154px;
    }

    .footer-section .right {
        width: 35%;
    }

    .filter-result-blk .content .title {
        font-size: 18px;
        line-height: 20px;
    }

    .filter-result-blk .content .sub-title {
        margin-top: 0;
    }

    .filter-by {
        width: auto;
        float: none;
        margin: 0 0 20px 0;
    }

    .filter-results-data {
        width: auto;
        float: none;
    }

    .filter-result-blk .image {
        width: 270px;
    }

    .filter-result-blk .content {
        width: calc(100% - 290px);
    }

    .deals-gallery.discover ul li {
        width: 31.2%;
    }

    .testimonial-blk-left,
    .testimonial-blk-right {
        width: auto !important;
        float: none !important;
    }

    .testimonial-blk .user-testimonial .top-quotes,
    .testimonial-blk .user-testimonial .bottom-quotes {
        width: 17px !important;
        height: 11px !important;
    }

    .testimonial-blk .user-testimonial {
        font-size: 15px;
        line-height: 20px;
        padding: 15px;
    }

    .deals-section .heading {
        line-height: 25px;
    }

    .deals-section .heading .float-left {
        max-width: 430px;
    }

    .deals-gallery .section .owl-theme .owl-nav {
        top: -50px;
    }

    .section {
        padding: 0 25px;
    }

    .header {
        position: fixed;
        width: calc(100% - 50px);
        background: black;
        top: 0;
    }

    .mobile-menu-icon,
    .mobile-search-icon {
        display: block;
        position: absolute;
        left: 25px;
        top: 18px;
        cursor: pointer;
    }

    .mobile-search-icon {
        right: 25px;
        left: inherit;
    }

    .mobile-menu {
        position: fixed;
        width: 265px;
        background: #151515;
        display: block;
        z-index: 20;
        height: 100vh;
        padding: 60px 25px 55px;
    }

    .mobile-menu-close {
        position: fixed;
        left: 315px;
        background: #000000;
        display: block;
        z-index: 2;
        text-align: right;
    }

    .mobile-menu .login-btn {
        background: #292929;
        border-radius: 8px;
        padding: 15px 20px;
        font-weight: 400;
        font-size: 17px;
        line-height: 26px;
        position: relative;
    }

    .mobile-menu .login-btn img {
        position: absolute;
        right: 8px;
        top: 8px;
    }

    .mobile-menu .write-to-us-btn {
        border-radius: 8px;
        padding: 15px 20px;
        font-weight: 400;
        font-size: 17px;
        line-height: 26px;
        position: relative;
    }

    .mobile-menu .write-to-us-btn img {
        position: absolute;
        right: 8px;
        top: 8px;
    }

    .mobile-menu ul {
        margin: 0;
        padding: 0;
        list-style: none;
        padding: 30px 0;
    }

    .mobile-menu ul li {
        padding: 0px 20px;
        font-weight: 400;
        font-size: 17px;
        line-height: 55px;
    }

    .mobile-menu ul li a {
        color: #ffffff;
    }

    .mobile-menu-overlay {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100vh;
        background: rgba(0, 0, 0, 0.5);
        z-index: 2;
    }
}
.pckg-type{
    display: flex;
    align-items: center;
    font-size: 13px;
    font-weight: 500;
    gap: 4px;
    white-space: normal; // prevents the text from staying on one line
    overflow-wrap: break-word;
    margin-top: 0px;
}

@media screen and (min-width: 768px) and (max-width: 960px) {
    .confirm-layout .left {
        width: 56%;
    }

    .confirm-layout .right {
        width: 37%;
    }

    .confirm-layout .left .room-image,
    .confirm-layout .left .room-content {
        width: auto;
        float: none;
    }

    .confirm-layout .left .room-image .img,
    .confirm-layout .left .room-content .head {
        margin-bottom: 15px;
    }

    .confirm-layout .left .room-content .room-date li:first-child,
    .confirm-layout .left .room-content .room-date li {
        display: block;
        padding: 0;
        border: 0 none;
    }

    .room-details .room-form-fields .form {
        width: auto;
        float: none;
        margin-bottom: 15px;
    }

    .room-details .room-form-fields .form img {
        right: 0;
    }

    .room-details .room-form-fields .form input {
        width: calc(100% - 35px);
    }

    .room-details .room-form-fields .form input.w50 {
        width: 100%;
    }

    .media-coverage-blk .media-details .image {
        float: none;
    }

    .media-coverage-blk .media-details .desc {
        width: auto;
        float: none;
        margin-top: 25px;
    }
}

@media screen and (min-width: 320px) and (max-width: 767px) {

    .header .menu,
    .header .search,
    .header .right-menu {
        display: none;
    }

    .header .search-mobile {
        display: block;
        float: none;
        margin: 0;
        border-radius: 0;
    }

    .header .search-input {
        display: block;
        position: fixed;
        top: 80px;
        left: 0;
        z-index: 3;
        width: 100%;
    }

    .header .logo {
        width: 85px;
        margin: 0 auto;
        float: none;
    }

    .main-banner .images {
        height: 80vh;
    }

    .main-banner .images .banner-image {
        height: 300px !important;
        object-fit: cover;
    }

    .about-section ul {
        text-align: center;
    }

    .about-section ul li {
        margin: 0 0 20px 0;
        width: 33%;
        display: inline-block;
    }

    .discover-international {
        padding: 30px;
    }

    .discover-international .left {
        width: 100%;
        float: none;
    }

    .discover-international .left .discover-blk {
        text-align: center;
    }

    .discover-international .right {
        float: none;
        width: auto;
    }

    .discover-international .right .discover-blk {
        background-size: 150% !important;
        background-position: center center !important;
        text-align: center;
        margin-top: 30px;
        border-radius: 10px;
    }

    .discover-international-section .head {
        text-align: left;
        font-size: 20px;
        line-height: 25px;
    }

    .discover-international .right .discover-blk .overlay {
        position: absolute;
        top: 50%;
        left: 50%;
        width: 100%;
        height: 100%;
        background: rgba(0, 0, 0, 0.5);
        transform: translate(-50%, -50%);
        border-radius: 10px;
    }

    .discover-international .left .heading,
    .discover-international .right .heading,
    .discover-international .left .offer,
    .discover-international .right .offer,
    .discover-international .left p,
    .discover-international .right p {
        position: relative;
    }

    .footer-section .footer-newsletter .input input {
        min-width: 154px;
    }

    .footer-section .right {
        width: 35%;
    }

    .section {
        padding: 0 20px;
    }

    .about-section ul li span {
        text-align: center;
        display: block;
        margin-right: 0;
    }

    .about-section ul li span.icon {
        width: 40px;
        margin: 0 auto !important;
    }

    .about-section ul li span.text {
        text-align: center;
        font-size: 11px;
        line-height: 15px;
    }

    // .about-section ul li:last-child {
    //     margin-bottom: 0;
    // }

    .deals-section .heading {
        text-align: left;
    }

    .deals-gallery ul li {
        width: auto;
    }

    .deals-gallery.discover ul li:first-child,
    .deals-gallery.discover ul li:last-child {
        margin: 10px;
    }

    .floating-icons ul li {
        margin-bottom: 10px;
    }

    .floating-icons ul li img {
        width: 45px;
    }

    .discover-left,
    .discover-right,
    .package-block .w70,
    .package-block .w30,
    .footer-section .left,
    .footer-section .right,
    .main-banner .left-text,
    .main-banner .right-text {
        width: auto;
        float: none;
    }

    .discover-left .heading {
        font-size: 28px;
        line-height: 28px;
    }

    .discover-left p {
        margin: 25px 0;
    }

    .discover-section {
        padding: 30px 0 0;
    }

    .package-block .w70 {
        margin: 0 0 20px 0;
    }

    .package-block {
        margin-bottom: 20px;
    }

    .discover-international .right .discover-blk {
        padding: 20px;
    }

    .discover-international .left .green-btn a,
    .discover-international .right .green-btn a {
        padding: 8px 50px;
    }

    .footer-section .right {
        margin-top: 30px;
    }

    // .main-banner .section {
    //     position: relative;
    //     top: inherit;
    //     width: auto;
    //     left: inherit;
    //     transform: inherit;
    // }

    .main-banner .section {
        position: absolute;
        top: calc(50% + 0px);
        width: calc(100% - 60px);
        left: 50%;
        transform: translate(-50%, -50%);
    }

    .main-banner .left-text h1.head {
        font-size: 18px;
        line-height: 22px;
    }

    .main-banner p.normal-text {
        font-size: 14px;
        line-height: 20px;
        margin-top: 12px;
    }

    .main-banner p.limited {
        font-size: 12px;
        line-height: 18px;
        margin-top: 5px;
    }

    .main-banner .green-btn {
        margin-top: 0px;
    }

    .main-banner .right-text,
    .main-banner .right-video {
        display: none;
    }

    .main-banner .images {
        height: auto;
    }

    .main-banner .section {
        transform: translate(-50%, 0%) !important;
        bottom: 5px !important;
        top: inherit !important;
    }

    .flip-countdown.size-small .flip-countdown-piece .flip-countdown-card .flip-countdown-card-sec {
        font-size: 14px;
    }

    .main-banner .left-text,
    .main-banner .right-text {
        text-align: center;
    }

    .main-banner .right-text {
        margin-top: 30px;
    }

    .flip-countdown {
        text-align: center;
    }

    .main-banner .right-text h2.sub-head {
        width: auto;
    }

    .main-banner {
        margin-top: 80px;
    }

    .footer-section .footer-newsletter .input input {
        margin: 0 0 10px 0;
        min-width: 250px;
    }

    .footer-section .footer-newsletter .input input.green-btn {
        width: 100%;
        margin-bottom: 0;
    }

    .login-container {
        padding: 35px;
    }

    .login-section .heading {
        font-size: 28px;
        line-height: 32px;
    }

    .footer-section {
        margin: 0;
        padding: 30px 0;
    }

    .filter-result-blk .content .title {
        font-size: 18px;
        line-height: 20px;
    }

    .filter-result-blk .content .offer-blk .left,
    .filter-result-blk .content .offer-blk .right {
        float: none;
    }

    .filter-result-blk .content .offer-blk .right {
        margin-top: 15px;
    }

    .filter-result-blk .content .offer-blk .right .book-btn {
        display: inline-block;
    }

    .filter-result-blk .content .sub-title {
        margin-top: 0;
    }

    .filter-results-data {
        width: auto;
        float: none;
    }

    .filter-result-blk .image,
    .filter-result-blk .content {
        width: 100%;
        float: none;
    }

    .filter-by,
    .inner-heading .head {
        width: auto;
        float: none;
        margin: 0 0 20px 0;
    }

    .filter-by {
        padding: 15px 0 5px 0;
    }

    .filter-results-data,
    .filter-section .sort-by,
    .filter-section .sort-results,
    .inner-heading .filter-section {
        width: auto;
        float: none;
    }

    .payment-section-blk .head {
        font-size: 30px;
        line-height: 35px;
    }

    .payment-section-blk .content .date-location .image {
        border-radius: 10px;
    }

    .payment-section-blk .content .date-location .image,
    .payment-section-blk .content .date-location .detail {
        width: initial;
        float: none;
    }

    .payment-section-blk .content .date-location .detail .head,
    .payment-section-blk .content .date-location .detail p,
    .payment-section-blk .content .date-location .detail .des span {
        text-align: center;
    }

    .footer-section .footer-logo .logo-text {
        margin-right: 5px;
    }

    .footer-section .footer-logo ul li {
        margin-right: 5px;
    }

    .w24 {
        width: 20px;
    }

    .footer-section .footer-menu ul li {
        width: 50%;
    }

    .deals-gallery.discover ul li {
        width: 31.2%;
    }

    .testimonial-blk-left,
    .testimonial-blk-right {
        width: auto !important;
        float: none !important;
    }

    .testimonial-blk .user-testimonial .top-quotes,
    .testimonial-blk .user-testimonial .bottom-quotes {
        width: 17px !important;
        height: 11px !important;
    }

    .testimonial-blk .user-testimonial {
        font-size: 15px;
        line-height: 20px;
        padding: 15px;
        max-height: 200px;
        overflow: hidden;
    }

    .deals-section .heading {
        line-height: 25px;
        font-size: 20px;
    }

    .deals-section .heading .float-left {
        // max-width: 320px;
        // margin: 0 auto;
        float: none;
    }

    .deals-gallery .section .owl-theme .owl-nav {
        top: -70px;
    }

    .deals-section .heading span.view-all,
    .deals-gallery .section .owl-theme .owl-nav {
        position: inherit;
        right: inherit;
        top: 10px;
    }

    .main-banner .right-text,
    .main-banner .right-video {
        float: none;
        width: 100%;
        text-align: center;
        margin: 15px 0;
    }

    .main-banner .right-text div,
    .main-banner .right-video div,
    .main-banner .right-text span,
    .main-banner .right-video span {
        text-align: center !important;
    }

    .filter-result-blk {
        padding: 10px;
    }

    .filter-result-blk .image {
        margin-bottom: 15px;
    }

    .details-content-left,
    .details-gallery .left {
        width: auto;
        float: none;
        margin-right: 0;
    }

    .details-content-right,
    .details-gallery .right {
        width: auto;
        float: none;
        margin-top: 20px;
    }

    .details-content-right .form-fields .w50 {
        margin: 20px 10px 0;
        width: calc(100% - 20px);
    }

    .details-content-right .form-fields .green-btn {
        margin: 25px 10px 0;
    }

    .details-content-right .total-amount {
        margin: 0 10px;
    }

    .details-gallery .right .image span.layer {
        width: 100%;
    }

    .details-content-left .content .itinerary-blk .itinerary .itinerary-image {
        width: auto;
        float: none;
        margin-right: 0px;
    }

    .details-content-left .content .itinerary-blk .itinerary .itinerary-image img {
        width: 100%;
        border-radius: 10px 10px 0 0;
    }

    .details-content-left .content .itinerary-blk .itinerary .itinerary-content {
        width: auto;
        padding: 15px;
    }

    .confirm-layout .left {
        width: auto;
        float: none;
        margin-bottom: 20px;
    }

    .confirm-layout .right {
        width: auto;
        float: none;
    }

    .confirm-layout .left .room-image,
    .confirm-layout .left .room-content {
        width: auto;
        float: none;
    }

    .confirm-layout .left .room-image .img,
    .confirm-layout .left .room-content .head {
        margin-bottom: 15px;
    }

    .confirm-layout .left .room-content .room-date li:first-child,
    .confirm-layout .left .room-content .room-date li {
        display: block;
        padding: 0;
        border: 0 none;
    }

    .room-details .room-form-fields .form {
        width: auto;
        float: none;
        margin-bottom: 15px;
    }

    .room-details .room-form-fields .form img {
        right: 0;
    }

    .room-details .room-form-fields .form input {
        width: calc(100% - 35px);
    }

    .room-details .room-form-fields .form input.w50 {
        width: 100%;
    }

    .media-coverage-blk .media-details .image {
        float: none;
    }

    .media-coverage-blk .media-details .desc {
        width: auto;
        float: none;
        margin-top: 25px;
    }

    .media-coverage-blk .media-details {
        width: auto;
        float: none;
    }

    .partners-blk .partners-content {
        width: calc(50% - 20px);
        margin-bottom: 15px;
    }

    .section {
        padding: 0 25px;
    }

    .header {
        position: fixed;
        width: calc(100% - 50px);
        background: black;
        top: 0;
    }

    .mobile-menu-icon,
    .mobile-search-icon {
        display: block;
        position: absolute;
        left: 25px;
        top: 18px;
        cursor: pointer;
    }

    .mobile-search-icon {
        right: 25px;
        left: inherit;
        width: 20px;
        top: 26px;
    }

    .mobile-menu {
        position: fixed;
        width: 265px;
        background: #151515;
        display: block;
        z-index: 23;
        height: 100vh;
        padding: 60px 25px 55px;
    }

    .mobile-menu-close {
        position: fixed;
        left: 315px;
        background: #000000;
        display: block;
        z-index: 3;
        text-align: right;
    }

    .mobile-menu .login-btn {
        background: #292929;
        border-radius: 8px;
        padding: 15px 20px;
        font-weight: 400;
        font-size: 17px;
        line-height: 26px;
        position: relative;
    }

    .mobile-menu .login-btn img {
        position: absolute;
        right: 8px;
        top: 8px;
    }

    .mobile-menu .write-to-us-btn {
        border-radius: 8px;
        padding: 15px 20px;
        font-weight: 400;
        font-size: 17px;
        line-height: 26px;
        position: relative;
    }

    .mobile-menu .write-to-us-btn img {
        position: absolute;
        right: 8px;
        top: 8px;
    }

    .mobile-menu ul {
        margin: 0;
        padding: 0;
        list-style: none;
        padding: 30px 0;
    }

    .mobile-menu ul li {
        padding: 0px 20px;
        font-weight: 400;
        font-size: 17px;
        line-height: 55px;
    }

    .mobile-menu ul li a {
        color: #ffffff;
    }

    .mobile-menu-overlay {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100vh;
        background: rgba(0, 0, 0, 0.5);
        z-index: 2;
    }

    .group-booking-blk .group-booking-list.left,
    .group-booking-blk .form-fields .form {
        float: none !important;
        width: auto !important;
    }

    .group-booking-blk .form-fields .form {
        margin-top: 15px !important;
    }

    .group-booking-blk .form-fields input,
    .group-booking-blk .form-fields textarea {
        width: calc(100% - 40px);
    }

    .our-client-love-us ul li {
        width: auto;
        float: none;
        padding: 15px 0 0 0;
    }

    .our-client-love-us ul li:first-child {
        padding-top: 0;
    }

    .our-client-love-us ul li img {
        width: 100%;
    }

    .my-account-blk .my-account-menu {
        width: auto;
        float: none;
        margin-right: 0;
    }

    .my-account-blk .my-account-menu ul li {
        display: inline-block;
        vertical-align: middle;
    }

    .my-account-blk .my-account-content {
        width: auto;
        float: none;
    }

    .my-booking-section .my-booking .image {
        width: auto;
        float: none;
    }

    .my-booking-section .my-booking .image img {
        width: 100%;
    }

    .my-booking-section .my-booking .content {
        width: auto;
        float: none;
        margin: 15px 0 0 0;
    }

    .my-booking-section .my-booking {
        padding: 15px;
    }

    .my-account-content .profile-pic-blk {
        padding: 20px 0;
    }

    .my-account-content .profile-pic-blk .picture .pic,
    .my-account-content .profile-pic-blk .picture {
        width: 100px;
        height: 100px;
        border-radius: 100px;
    }

    .my-account-content .profile-pic-blk .picture .pic img {
        height: 100%;
    }

    .my-account-blk .my-account-menu ul li {
        padding: 15px 15px 15px 60px;
        font-size: 15px;
    }

    .my-account-blk .my-account-menu ul li span {
        top: 10px;
    }

    .black-bg .partners-blk .partners-content {
        width: auto;
    }

    .filter-result-blk .content .offer-blk {
        overflow: inherit;
        position: relative;
        bottom: inherit;
        width: auto;
    }

    .breadcrum {
        visibility: hidden;
        padding: 0;
    }

    .breadcrum ul {
        height: 18px;
        overflow: hidden;
    }

    .gallery-popup .images-small-blk {
        display: none;
    }

    .gallery-popup .image-big {
        width: auto;
        float: none;
        margin-left: 0;
    }

    .deals-section .heading span.view-all {
        display: none;
    }

    .deals-gallery.discover ul li {
        margin: 0 0 10px 0 !important;
    }

    .deals-gallery {
        padding: 0;
    }

    .packages-section .heading {
        font-size: 20px;
        line-height: 25px;
    }

    .package-block .image .name,
    .deals-gallery.discover span.name {
        font-size: 18px;
        line-height: 20px;
    }

    .confirm-blk {
        padding: 10px 0 40px 0;
    }

    .login-container .login-text a {
        display: block;
    }

    .group-booking-blk {
        padding-top: 20px;
    }

    .group-booking-blk .head {
        font-size: 25px;
        line-height: 40px;
    }

    .group-booking-list-blk {
        margin-top: 20px;
    }

    .group-booking.gray-bg {
        padding: 0;
    }
    
    .group-booking-blk .form-fields {
        margin: 0;
    }

    .group-booking-blk .form-fields .button-form {
        margin-top: 20px;
    }

    .group-booking-blk.glimpses {
        padding: 0;
    }

    .login-container .form-fields input.green-btn.login {
        width: 100%;
    }
}

@media screen and (min-width: 320px) and (max-width: 431px) {
    .deals-gallery.discover ul li {
        width: auto;
    }

    .testimonial-section .testimonial-blk {
        padding: 10px 5px;
    }

    .partners-blk .partners-content {
        width: auto;
        margin-bottom: 15px;
        float: none;
        display: inline-block;
        vertical-align: middle;
    }

    .partners-blk .partners-content .content {
        text-align: center;
    }

    .single-line .left,
    .single-line .right {
        width: auto !important;
        float: none;
    }

    .details-content-right .total-amount .right.bold {
        width: 50%;
        text-align: right;
    }

    .details-content-right .total-amount .right.bold input {
        width: calc(100% - 42px);
    }
}

.background-overlay {
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.8) 0%, rgba(0, 0, 0, 0) 103.75%);
    position: absolute;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 15px;
    bottom: 0;
}

.position-relative {
    position: relative;
}

.lato-font{
    font-family: 'Lato', sans-serif;
}

.owl-carousel .owl-nav button.owl-prev,
.owl-carousel .owl-nav button.owl-next,
.owl-carousel button.owl-dot {
    font-size: 48px;
}

::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 7px;
}

::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: rgba(255, 255, 255, 0.5);
    -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, .5);
}

.details-content-left .content .tabs::-webkit-scrollbar {
    height: 7px;
}

.details-content-left .content .tabs::-webkit-scrollbar-thumb {
    background-color: rgba(0, 0, 0, 0.5);
}

.loader-container {
    position: fixed;
    height: 100vh;
    background: rgba(0, 0, 0, 0.4);
    width: 100%;
    z-index: 200;
    top: 0;
    left: 0;
}

.loader-container .loader {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}
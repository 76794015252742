.brand-calender-wrapper {
  display: flex;
  flex-direction: column;
  gap: 20px;
  align-items: flex-start;
}
.right-side-wrapper {
  display: flex;
  gap: 10px;
  // align-items: center;
  flex-direction: column;
  justify-content: space-between;
  .expand-btn {
    padding: 8px;
    border: none;
    background-color: transparent;
    color: white;
  }
}
.details-sticky-card {
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.calender-holder-sticky {
  //   flex-grow: 1;
  display: flex;
  align-items: center;
  gap: 10px;
  background-color: transparent !important;
  // justify-content: space-between;
  // flex-grow: 1;
  .calender-icon-wrapper {
    display: flex;
    border-radius: 5px;
    background-color: white;
    // border: 1px solid #ddd;
    .calender-styles {
      padding: 8px;
      background-color: transparent;
      width: 100%;
      border: none;
      max-width: 150px;
    }
    .calender-icon {
      background-color: transparent;
      border: none;
    }
  }
}
.tab-vew-text {
  display: none;
}
.pricing-btn-title {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
  button {
    background: #2e8b98;
    border-radius: 6px;
    font-weight: 500;
    font-size: 12px;
    line-height: 24px;
    text-transform: uppercase;
    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    display: inline-block;
    color: #ffffff;
    cursor: pointer;
    border: none;
    padding: 8px 26px;
  }
}
.pricing-title {
  color: #fff;
  font-size: 19px;
  font-weight: 500;
  display: block;
}
.travellers-div {
  display: flex;
  align-items: center;
  gap: 10px;
  flex-grow: 1;
  .select-holder {
    flex-grow: 1;
  }
}
.select-menu {
  padding: 6.5px;
  border-radius: 5px;
  width: 100%;
  background-color: white;
}
.travellers-holder {
  display: flex;
  flex-direction: column;
  gap: 12px;
  flex-grow: 1;
}
.children-wrapper {
  display: flex;
  align-items: flex-start;
  justify-content: flex-end;
  gap: 10px;
  label {
    flex-shrink: 0;
  }
  .select-holder {
    display: flex;
    gap: 10px;
    flex-wrap: wrap;
    // min-width: 140px;
    // flex-grow: 1;
    select {
      // min-width: 120px;
      width: 105px;
    }
  }
}
.non-tab {
  display: none;
}
.right-side-holder {
  display: flex;
  flex-direction: column;
  gap: 12px;
  width: 100%;
}
.responsive-menus {
  display: none;
}
.responsive-menus-next {
  display: block;
}
@media screen and (min-width: 1024px) {
  .non-tab {
    display: block;
  }
  .responsive-menus {
    display: block;
  }
  .travellers-div {
    select {
      padding: 6.5px;
      border-radius: 5px;
      //   width: 150px;
      width: 100%;
      background-color: white;
      min-width: 106px;
    }
    .children-wrapper {
      padding-right: 80px;
      .select-holder {
        flex-direction: row;
        gap: 12px;
        // min-width: 140px;
        flex-grow: 1;
        select {
          width: 100%;
        }
      }
    }
  }

  .children-wrapper {
    padding-right: 47px;
  }

  .right-side-wrapper {
    flex-direction: row;
    gap: 40px;
    justify-content: flex-end;
    align-items: center;
  }
  .brand-calender-wrapper {
    display: flex;
    flex-direction: row;
    // align-items: center;
  }
  .calender-styles {
    // width: 150px !important;
  }
  .pricing-title {
    font-size: 18px;
  }
  .brand-calender-wrapper {
    gap: 60px;
    // align-items: center;
  }
}

.mobile-view {
  display: none;
}

.tab {
  display: none;
}
.select-wrapper {
  display: flex;
  gap: 10px;
  // flex-direction: column;
  width: 100%;
  // gap: 10px;
}
// @media screen and (min-width:1091px) {

//   .children-wrapper-initial{
//     padding-right: 240px;
//   }
// }
@media screen and (max-width: 1023px) {
  .log-container {
    display: none;
  }
  .tab {
    display: block;
  }
  .responsive-menus-next {
    display: none;
  }
  .mobile-view {
    display: block;
  }
}
.mobile-lable {
  min-width: 100px;
}
.calender-wrapper {
  display: flex;
  gap: 10px;
  align-items: center;
  flex-grow: 1;
}
.children-label{
  margin-top: 9PX;
}
@media screen and (max-width: 767px) {
  .travellers-div {
    align-items: flex-start;
  }

  .pricing-title {
    font-size: 14px;
    display: none;
  }
  .tab-vew-text {
    display: block;
    font-size: 14px;
  }
  .right-side-wrapper {
    gap: 12px;
  }
  .pricing-btn-title {
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    gap: 14px;
    button {
      align-self: stretch;
    }
  }
  .select-wrapper {
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 10px;
  }
}

@media screen and (max-width: 1023px) {
  .calender-icon-wrapper {
    flex-grow: 1;
    .calender-icon {
      flex-shrink: 0;
    }
  }
  .calender-holder-sticky {
    .calender-icon-wrapper {
      .react-datepicker-wrapper{
        width: 100%;
      }
    }
  }
}

@media screen and (max-width: 425px) {
  .children-wrapper {
    .select-holder {
      flex-direction: row;
      gap: 12px;
      flex-grow: 1;
      select {
        width: 100%;
      }
    }
  }
  .calender-holder-sticky {
    .calender-icon-wrapper {
      .calender-styles {
        font-size: 12px;
      }
    }
  }
  .right-side-wrapper {
    .expand-btn {
      padding: 0px;
    }
  }
  .children-label{
    max-width: 75px;
    margin-top: 0px;
  }
  .mobile-lable{
    min-width: 75px !important;
  }
}

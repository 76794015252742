.header-wrapper {
  width: 100%;
  height: auto;
  background: rgba(40, 40, 40, 0.9);
  position: absolute;
  top: 0;
  left: 0;
  z-index: 3;
}

.section {
  max-width: 1140px;
  overflow: hidden;
  padding: 0 70px;
  margin: 0 auto;
}

.is-sticky {
  position: sticky;
  z-index: 100;
  top: 0;
}

.non-stikcy{
  position: relative;
}

.header {
  height: 80px;
  overflow: inherit;
}

.header .logo {
  font-weight: 400;
  font-size: 20px;
  line-height: 30px;
  float: left;
}

.header .logo a {
  color: #1ab2d2;
  // line-height: 80px;
  display: inline-block;
  margin-top: 24px;
}

.header .menu {
  float: left;
  margin-left: 40px;
}

.header .menu ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

.header .menu ul li {
  display: inline-block;
  vertical-align: middle;
  margin-right: 20px;
}

.header .menu ul li a {
  font-weight: 400;
  font-size: 13px;
  line-height: 16px;
  color: #ffffff;
  line-height: 76px;
  display: inline-block;
  border-bottom: 4px solid transparent;
}

.header .menu ul li a.active,
.header .menu ul li a:hover {
  border-bottom: 4px solid #2e8b98;
}

.header .right-menu {
  float: right;
}

.header .right-menu ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

.header .right-menu li {
  display: inline-block;
  vertical-align: middle;
  margin: 0;
  position: relative;
}

.header .right-menu li a {
  display: inline-block;
  margin: 25px 0;
  border-right: 1px solid rgba(255, 255, 255, 0.3);
  padding: 0 15px;
}

.header .right-menu li span {
  font-weight: 400;
  font-size: 13px;
  line-height: 16px;
  color: #ffffff;
  line-height: 76px;
  display: inline-block;
  border-bottom: 4px solid transparent;
  margin: 0;
}

.header .right-menu li:last-child a {
  border-right: 0 none;
}

.header .right-menu li a img {
  display: inline-block;
  vertical-align: middle;
}

.header .right-menu li a img.profile {
  height: 30px;
  width: 30px;
  border-radius: 50%;
  margin-right: 5px;
}

.header .right-menu li a span.profile {
  height: 30px;
  width: 30px;
  border-radius: 50%;
  margin-right: 5px;
}

.header .right-menu li ul {
  position: absolute;
  background: rgba(40, 40, 40, 0.9);
  top: 80px;
  z-index: 30;
  right: 0;
  list-style: none;
  margin: 0;
  padding: 0;
  display: none;
}

.header .right-menu li:hover ul {
  display: block;
}

.header .right-menu li ul li {
  display: block;
}

.header .right-menu li ul li a,
.header .right-menu li ul li span {
  display: block;
  font-weight: 400;
  font-size: 13px;
  line-height: 16px;
  color: #ffffff;
  line-height: 20px;
  padding: 8px 20px;
  margin: 0;
}

.header .search {
  background: #1f1f1f;
  box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.16);
  border-radius: 95px;
  float: right;
  padding: 15px 50px;
  position: relative;
  margin: 14px 30px 14px 0;
}

.header .search .location-icon {
  position: absolute;
  left: 20px;
  top: 17px;
}

.header .search .search-icon,
.header .search .close-icon {
  position: absolute;
  right: 20px;
  top: 17px;
}

.header .search .close-icon {
  right: 44px;
}

.header .search input {
  background: none;
  border: 0 none;
  width: 175px;
  color: #26e4ff;
}

.header .search input::-webkit-input-placeholder {
  /* Edge */
  color: #26e4ff;
}

.header .search input:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #26e4ff;
}

.header .search input::placeholder {
  color: #26e4ff;
}

.mobile-menu-icon,
.mobile-search-icon,
.mobile-menu,
.mobile-menu-close {
  display: none;
}

.show-mobile-menu {
  display: block !important;
}

.hide-mobile-menu {
  display: none !important;
}

@media screen and (min-width: 1024px) and (max-width: 1151px) {
  .header .menu ul li {
    margin-right: 10px;
  }
  .header .search input {
    width: 100px;
  }
  .header .right-menu li a {
    padding: 0px;
  }
  .header .search {
    margin: 14px 12px 14px 0px !important;
  }
}

.discover-blk {
    padding: 25px;
    text-align: right;
    border-radius: 10px;
    position: relative;
    overflow: hidden;
    min-height: 200px;
}

.discover-international .left .discover-blk .background-overlay {
    display: none;
}
.header-btn-grp {
  display: flex;
  align-items: center;
  // flex-grow: 1;
  gap: 20px;
  justify-content: space-between;
  float: right;
}
.dialog-login {
  max-width: 100% !important;
}

.cities-input-section {
  // max-width: 1140px;
  // width: 100%;
  // margin-top: 3.5%;
  // padding: 20px 0px;
  display: flex;
  flex-direction: row;
  align-items: center;
  align-content: center;
  gap: 10px;
}

.slider-div {
  color: #00000080;
  width: 12%;
  text-align: center;
}

.cities-input-div {
  max-width: 1140px;
  width: 26%;
  display: flex;
  flex-direction: column;
}

.main-cities-text {
  width: 245px;
  padding-left: 20px;
  border-radius: 7px;
  height: 32px;
  border: 1px solid rgba(0, 0, 0, 0.2);
  background-color: rgba(251, 251, 251, 1);
  font-weight: 600;
  margin-top: 10px;
}

.section-custom-itinerary {
  max-width: 1440px;
  display: flex;
  justify-content: center;
  width: 100%;
  overflow: hidden;
  margin: 0 auto;
}

.ci-header-section {
  // position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  // gap: 9px;
  // align-items: center;
  width: 100%;
  height: auto;
}

.heading-text-div {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}

.dates-people-selection-div {
  // width: 1140px;
  display: flex;
  justify-content: space-between;
  gap: 14px;
  flex-wrap: wrap;
  margin-bottom: 1.8%;
}

.dates-selection-div,
.people-selection-div {
  display: flex;
  gap: 20px;
}

.dates-selection-from,
.dates-selection-to {
  display: flex;
  flex-direction: column;
}

.people-selection-div {
  // width: 23%;
  justify-content: space-between;
}

.stepper-div {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  height: 20%;
}

.transfer-data-div {
  margin-top: 40px;
}

.transfer-step {
  display: flex;
  flex-direction: row;
  align-items: center;
  align-content: center;
  margin-bottom: 12px;
}

.city-distance-transport-div {
  display: flex;
  // width: 63%;
  width: 100%;
  justify-content: space-between;
}

.city-distance-transport-div-view {
  display: flex;
  // width: 67%;
  justify-content: space-between;
}

.city-distance-transport-div-view-resp {
  display: flex;
  width: 85%;
  justify-content: space-between;
}

.transport-distance-div {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.city-distance-div {
  display: flex;
  flex-direction: column;
  // flex-shrink: 0;
  justify-content: space-around;
  margin-left: 10px;
  margin-right: 10px;
  margin-top: -10px;
}

.city-link {
  color: rgba(0, 0, 0, 1);
  font-weight: 600;
  font-size: 12px;
  line-height: 10px;
}

.distance-time-link {
  color: rgba(0, 0, 0, 0.5);
  font-size: 10px;
}

.step-label-div {
  display: flex;
  flex-direction: row;
}

.adults-selection,
.children-selection {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 1rem;
  width: 100%;
  align-items: center;
}

.label-element {
  font-size: 12px;
  line-height: 18px;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.5);
}

.city-mandatory-text {
  margin-top: 20px;
  font-size: 12px;
  line-height: 18px;
  font-weight: 400;
  color: rgba(212, 10, 10, 0.5);
}

.dates-mandatory-text {
  font-size: 12px;
  line-height: 18px;
  font-weight: 400;
  color: rgba(143, 12, 12, 0.5);
}

.people-selection-btn {
  width: 40px;
  height: 40px;
  padding: 8px;
  border: 1px solid black;
  background: none;
  border-radius: 4px;
  font-size: large;
  color: rgba(0, 0, 0, 1);
}

.count-text {
  color: #000;
  margin-bottom: 8px;
  font-weight: bolder;
  width: 12px;
}

.basic-multi-select {
  width: 450px;
  color: #000;
  margin-top: 10px;
  height: auto;
}

.date-picker {
  padding: 5px 20px 5px 10px;
  background-color: rgba(251, 251, 251, 1);
  border-radius: 6px;
  font-weight: 600;
  font-size: 13px;
  line-height: 16px;
  width: 100px;
  border: 1px solid rgba(0, 0, 0, 0.2);
  color: #000;
  margin-top: 10px;
  height: 24px;
}

.itinerary-plan-name {
  width: 70%;
  padding-left: 20px;
  border-radius: 7px;
  height: 32px;
  border: 1px solid rgba(0, 0, 0, 0.2);
  background-color: rgba(251, 251, 251, 1);
  font-weight: 600;
}

.outer-itinerary-div {
  display: flex;
  align-items: center;
  // flex-direction: column;
  // justify-content: center;
  position: relative;
  min-width: 100%;
  width: 100%;
  background: linear-gradient(to bottom, transparent 50%, white 50%),
    url("/assets/images/custom-itinerary-bg.jpg");
  min-height: 555px;
  height: auto;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: top;
  // display: grid;
  // place-items: center;
}

.outer-itinerary-div-resp {
  display: flex;
  position: relative;
  min-width: 100%;
  width: 100%;
  background: linear-gradient(to bottom, transparent 50%, white 50%),
    url("/assets/images/custom-itinerary-bg.jpg");
  height: auto;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: top;
  display: grid;
  place-items: center;
}

.itinerary-planning-cities-heading-div {
  display: flex;
  justify-content: space-between;
}

.heading-edit-text-div {
  display: flex;
}

.heading-edit-btn {
  background: none;
  border: none;
  margin-left: 10px;
  cursor: pointer;
}

.tab-content {
  // width: 1140px;
}

.tab-content-resp {
  width: 100%;
}

.inner-itinerary-div {
  // width: 1140px;
  background-color: rgba(256, 256, 256, 0.95);
  padding: 20px;
  border-radius: 8px;
  height: auto;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  margin-top: 16px;
  margin-bottom: 16px;
}

.inner-itinerary-div-resp {
  background-color: rgba(256, 256, 256, 0.95);
  padding: 20px;
  border-radius: 8px;
  height: auto;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  margin-top: 10px;
  margin-bottom: 10px;
}

.inner-itinerary-div-no-data {
  // width: 100%;
  background-color: rgba(256, 256, 256, 0.95);
  padding: 20px;
  border-radius: 8px;
  min-height: 225px;
  // min-width: 1140px;
  height: auto;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  display: flex;
}

.outer-itinerary-div-no-data {
  display: flex;
  align-items: center;
  position: relative;
  width: 100%;
  background: linear-gradient(to bottom, transparent 50%, white 50%),
    url("/assets/images/custom-itinerary-bg.jpg");
  min-height: 555px;
  height: auto;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: top;
  // display: grid;
  // place-items: center;
}

.no-data-heading {
  color: black;
  width: 100%;
  align-items: center;
  align-content: center;
  text-align: center;
  font-size: x-large;
}

.arrival-departure-input-section {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 12px;
  // padding: 5px 30px 5px 30px;
}

.arrival-departure-input-section-resp {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 12px;
  // padding: 5px 30px 5px 30px;
}

.departure-input-section,
.arrival-input-section {
  width: 50%;
  display: flex;
  flex-direction: row;
  // justify-content: center;
  align-items: center;
}

.departure-input-section-resp,
.arrival-input-section-resp {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 5px;
  margin-top: 5px;
}

.arrival-departure-input-div {
  display: flex;
  flex-direction: row;
  gap: 12px;
  width: 100%;
}

.itinerary-planning-cities-heading,
.itinerary-planning-distances-heading {
  font-weight: 500;
  font-size: 18px;
  line-height: 30px;
  color: rgba(0, 0, 0, 1);
}

.arrival-text,
.departure-text {
  width: 70%;
  padding-left: 20px;
  border-radius: 7px;
  height: 42px;
  border: 1px solid rgba(0, 0, 0, 0.2);
  background-color: rgba(251, 251, 251, 1);
  font-weight: 600;
}

.arrival-text-select,
.departure-text-select {
  width: 70%;
  border-radius: 7px;
  height: 42px;
  background-color: rgba(251, 251, 251, 1);
  font-weight: 600;
}

.itinerary-planning-div {
  margin-top: 20px;
  display: flex;
  gap: 20px;
  flex-direction: row;
  justify-content: space-between;
  height: auto;
  // padding: 10px;
}

.itinerary-planning-div-resp {
  margin-top: 4px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: auto;
  padding: 10px;
}

.optimize-text-div {
  text-align: center;
  margin-bottom: 10px;
  color: #00000080;
  font-weight: 400;
  font-size: 12px;
  line-height: 24px;
  margin-top: -15px;
}

.itinerary-planning-cities,
.itinerary-planning-distances {
  // width: 45%;
  // margin: 8px;
  // flex-grow: 1;
  flex: 1;
  border-radius: 10px;
  padding: 25px;
}

.itinerary-planning-cities-resp,
.itinerary-planning-distances-resp {
  margin: 8px;
  border-radius: 10px;
  padding: 25px;
}

.itinerary-planning-distances {
  display: flex;
  flex-direction: column;
}

.stays-places-to-visit-section {
  background: rgba(210, 246, 255, 1);
  padding-bottom: 30px;
  // display: flex;
  // flex-direction: column;
  // align-items: center;
}

.footer-btn-div {
  // margin-top: 30px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.footer-left-div,
.footer-right-div {
  display: flex;
  flex-direction: row;
}

.time-place-div {
  display: flex;
  flex-direction: column;
  gap: 3%;
}

.stays-places-to-visit-heading {
  padding: 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.stays-places-to-visit-heading h1 {
  color: black;
  margin-bottom: 10px;
  font-size: 32px;
  text-align: center;
}

.stays-places-to-visit-heading p {
  color: black;
  font-size: 14px;
  text-align: center;
  line-height: 5px;
}

.itinerary-button-section {
  margin-top: 2px;
  display: flex;
  justify-content: center;
  text-align: center;
  align-items: center;
  align-content: center;
  left: 50%;
  gap: 1%;
  margin-bottom: 10px;
}

.optimize-btn {
  border: none;
  width: 108px;
  height: 40px;
  border-radius: 4px;
  cursor: pointer;
  color: white;
  background: linear-gradient(
    45deg,
    rgba(119, 58, 255, 1),
    rgba(180, 116, 253, 1)
  );
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
}

.optimize-btn:disabled {
  border: none;
  width: 108px;
  height: 40px;
  border-radius: 4px;
  cursor: pointer;
  color: white;
  background: #aaa;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
}

.validation-btn-div {
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 3%;
  color: #aaa;
}

.apply-to-stays-btn {
  border: none;
  width: 108px;
  height: 40px;
  border-radius: 4px;
  cursor: pointer;
  color: white;
  background: rgba(46, 139, 152, 1);
}

.itinerary-planning-cities {
  background-color: rgba(219, 236, 240, 1);
}

.itinerary-planning-distances {
  background-color: rgba(243, 236, 227, 1);
}

.itinerary-planning-cities-resp {
  background-color: rgba(219, 236, 240, 1);
}

.itinerary-planning-distances-resp {
  background-color: rgba(243, 236, 227, 1);
}

.add-destination-div {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 25px;
  gap: 4%;
}

.optimize-div {
  padding-top: 20px;
}

.add-destination-btn,
.suggest-btn {
  width: 40%;
  background: transparent;
  border: 1px solid rgba(26, 178, 210, 1);
  border-radius: 4px;
  height: 40px;
  color: rgba(26, 178, 210, 1);
  font-weight: 500;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}

.cities-list-div {
  margin-top: 16px;
  width: 100%;
}

.section-custom-itinerary {
  max-width: 1440px;
  overflow: hidden;
  padding: 0 70px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
}

.breadcrumb-section {
  display: flex;
  max-width: 1140px;
  align-items: center;
  align-content: center;
  justify-content: start;
  width: 1140px;
}

.inner-heading-div {
  // max-width: 1140px;
  // width: 1140px;
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  margin-bottom: 1%;
}

.inner-heading-div-resp {
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  margin-bottom: 1%;
  padding: 20px;
}

.ci-head {
  color: #fff;
}

.heading-txt {
  font-size: x-large;
}

.arrival-departure-text {
  font-weight: 700;
}

.custom-itinerary-page {
  background: #fff;
}

.custom-itinerary {
  position: absolute;
  background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #ffffff 100%);
  width: 100%;
  min-height: 395px;
}

.arrival-destination-div-label {
  font-size: 16px;
  line-height: 24px;
  color: rgba(17, 17, 17, 0.7);
  margin-right: 10px;
  font-weight: 500;
  width: 110px;
  text-align: end;
}

.section-one-heading {
  padding: 20px;
  color: #fff;
}

.text-center {
  color: #fff;
}

.details-content-itinerary {
  width: 100%;
  display: flex;
  flex-direction: row;
  color: #111111;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  border-radius: 10px;
  padding: 20px;
  width: 380px;
  gap: 30px;
}

.details-content-itinerary .form-fields {
  color: beige;
  display: flex;
  flex-direction: row;
  align-items: center;
  overflow: inherit;
  min-height: 90px;
}

.details-content-itinerary .form-fields .form-label {
  margin-top: 20px;
  width: 9%;
  color: #000;
  margin-right: 10px;
}

.form-label {
  white-space: nowrap;
  margin-right: 10px;
}

.adults-children-label {
  margin-right: 10px;
}

.details-content-itinerary .form-fields .w50 {
  flex-direction: row;
  width: calc(30% - 20px);
  position: relative;
  margin: 0 10px;
  margin-top: 20px;
}

.details-content-itinerary .form-fields input,
.details-content-itinerary .form-fields .w50 select {
  padding: 5px 20px 5px 10px;
  background: white;
  //box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.16);
  border-radius: 6px;
  font-weight: 600;
  font-size: 13px;
  line-height: 16px;
  width: calc(100% - 62px);
  border: 1px solid #000;
  color: #000;
}

.icon-img {
  margin-right: 10px;
}

.details-content-itinerary .form-fields .w50 select {
  width: 100%;
  background-repeat: no-repeat;
  background-position-x: 95%;
  background-position-y: 10px;
}

.details-content-itinerary .form-fields span.icon {
  position: absolute;
  right: 40px;
  top: 6px;
  height: 25px;
  cursor: pointer;
}

.details-content-itinerary .form-fields span img {
  height: 20px;
}

.itinerary-plan-button-plain {
  display: flex;
  flex-direction: row;
  background: none;
  border: none;
  gap: 5%;
  align-items: center;
  margin-right: 8px;
  cursor: pointer;
}

.itinerary-card-btn-div {
  display: flex;
  flex-direction: row;
}

.arrival-destination-div {
  padding: 20px;
  text-align: center;
  align-items: center;
  justify-content: center;
  display: flex;
  flex-direction: row;
  gap: 10px;
}

.arrival-destination-div input {
  border-radius: 6px;
  border: 1px solid #000;
  padding: 5px 10px;
  color: #000;
}

.transfermode-itinerary {
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 4px;
  height: 25px;
  background-color: rgba(255, 255, 255, 1);
  color: rgba(0, 0, 0, 1);
  font-size: 14px;
  line-height: 16px;
  width: 100%;
  font-weight: 600;
  padding: 6px;
  margin-left: 10px;
}

.city-itinerary-planning-div {
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.plan-cities-select {
  width: 160.75px;
  border-radius: 7px;
  height: 42px;
  font-weight: 600;
}

.nights-itinerary {
  background: rgba(255, 255, 255, 1);
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 4px;
  height: 37px;
  margin-top: -2px;
  width: 160.75px;
  color: rgba(0, 0, 0, 1);
  font-size: 14px;
  line-height: 16px;
  font-weight: 600;
  padding: 6px;
}

.cityname-itinerary {
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 4px;
  height: 30px;
  background-color: rgba(255, 255, 255, 1);
  color: rgba(0, 0, 0, 1);
  font-size: 14px;
  line-height: 16px;
  width: 160.75px;
  font-weight: 600;
  padding: 6px;
}

.transfers-itinerary {
  background: rgba(255, 255, 255, 1);
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 4px;
  height: 42px;
  // width: 160.75px;
  width: 100%;
  color: rgba(0, 0, 0, 1);
  font-size: 14px;
  line-height: 16px;
  font-weight: 600;
  padding: 6px;
  margin-left: 10px;
}

.ptv-time-from,
.ptv-time-to {
  padding: 5px 10px;
  background: white;
  border-radius: 4px;
  font-weight: 600;
  font-size: 13px;
  line-height: 20px;
  width: 104px;
  border: 1px solid #00000033;
  color: #000;
  margin-left: 3px;
}

.day-index-select {
  padding: 5px 10px;
  background: white;
  border-radius: 4px;
  font-weight: 600;
  font-size: 13px;
  line-height: 20px;
  width: 104px;
  border: 1px solid #00000033;
  color: #000;
  margin-left: 3px;
}

.accordian-header-input {
  margin-left: 10px;
  border-radius: 5px;
  border: 1px solid #00000033;
  padding: 8px;
  font-weight: 600;
  height: 15px;
}

.city-input-div {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-content: center;
  margin-top: 5px;
  align-items: center;
}

.ptv-city-list {
  padding: 5px 10px;
  background: white;
  border-radius: 4px;
  font-weight: 600;
  font-size: 13px;
  line-height: 20px;
  border: 1px solid #00000033;
  color: #000;
  width: 211px;
}

.itinerary-plan-button-div {
  padding: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.itinerary-plan-button {
  display: inline;
  margin-left: 10px;
  //box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.15);
  border-radius: 5px;
  padding: 10px 25px;
  cursor: pointer;
  background: #2e8b98;
  color: #fff;
  border: none;
  height: fit-content;
}

.add-place-to-visit-btn {
  display: inline;
  border-radius: 5px;
  padding: 10px 25px;
  cursor: pointer;
  background: #2e8b98;
  color: #fff;
  border: none;
  margin-left: 10px;
}

.add-place-to-visit-btn:disabled {
  background: #aaa;
}

.save-place-to-visit-btn {
  display: inline;
  border-radius: 5px;
  padding: 10px 25px;
  cursor: pointer;
  background: #24a86b;
  color: #fff;
  border: none;
  margin-left: 10px;
}

.add-place-to-visit-div {
  display: flex;
  width: 100%;
  margin-top: 10px;
  justify-content: end;
  margin-bottom: 10px;
}

.itinerary-planning-section {
  background-color: #f7f6f6;
  border-radius: 10px;
  margin-bottom: 40px;
  padding-bottom: 20px;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.15);
}

.tabs {
  display: flex;
  justify-content: center;
  width: 100%;
}

.btn-div {
  background: #fff;
  padding: 6px 6px 6px 6px;
  border-radius: 56px;
}

.stays-btn,
.places-to-visit-btn {
  display: inline;
  padding: 15px 25px;
  cursor: pointer;
  background: #000;
  color: #fff;
  border: none;
  border-radius: 56px;
}

.tab-active {
  background: #fff;
  color: #000;
}

.places-to-visit-div {
  display: flex;
  flex-direction: row;
  margin-bottom: 20px;
  margin-top: 5px;
  align-items: center;
}

.place-to-visit-item {
  display: flex;
  flex-direction: row;
  gap: 9px;
  margin-bottom: 5px;
  margin-top: 5px;
  justify-content: space-between;
  width: 100%;
}

.place-to-visit-item-resp {
  display: flex;
  flex-direction: column;
  margin-bottom: 5px;
  margin-top: 5px;
  justify-content: space-between;
  width: 100%;
}

.places-of-interest-div {
  width: 550px;
}

.places-of-interest-div-view {
  width: 700px;
}

.from-to-city-div {
  display: flex;
  flex-direction: column;
  height: 70px;
  width: 310px;
  justify-content: space-between;
}

.from-to-city-div-resp {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
}

.from-to-div,
.cities-div {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.from-to-input-div {
  display: flex;
  flex-direction: row;
}

.places-to-visit-timing,
.places-to-visit-cities {
  display: flex;
  flex-direction: row;
  margin-right: 20px;
  align-items: center;
}

.places-to-visit-section {
  margin-top: 20px;
  margin-bottom: 20px;
  background-color: #fff;
  color: #000;
  padding: 20px;
  border-radius: 6px;
  box-shadow: 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.places-to-visit-section-resp {
  margin: 20px;
  background-color: #fff;
  color: #000;
  padding: 20px;
  border-radius: 6px;
  box-shadow: 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.places-to-visit-content-section {
  margin-top: 30px;
  background-color: #f4fdff;
  padding: 20px;
  border-radius: 15px;
}

.places-to-visit-content-section-resp {
  margin-top: 30px;
  background-color: #f4fdff;
  padding: 20px;
  border-radius: 15px;
  height: auto;
}

.timing-label,
.cities-label,
.suggested-places-txt {
  color: #00000080;
  font-weight: 400;
  font-size: 12px;
  line-height: 24px;
  flex-shrink: 0;
}

.accordian-header-section {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.accordian-header-div {
  display: flex;
  align-items: center;
  align-content: center;
}

.last-day-div {
  margin-left: 10px;
  display: flex;
  height: 100%;
  color: red;
  font-size: 12px;
  font-weight: 600;
}

.last-day-index {
  background-color: #24a86b;
  height: 100%;
  color: red;
  font-size: 12px;
  font-weight: 600;
}

.accordian-header-btn,
.property-change-btn {
  background: #2e8b98;
  color: #fff;
  border: none;
  padding: 10px;
  border-radius: 6px;
  cursor: pointer;
}

.horizontal-divider {
  background-color: #ccc;
  height: 2px;
  border: none;
  width: 95%;
}

.accordian-header-btn {
  padding: 5px 10px 5px 10px;
  font-size: larger;
}

.accordian-header {
  display: flex;
  gap: 10px;
  align-items: center;
}

.accordian-header-text {
  color: black;
}

.accordian-image {
  height: 15px;
  transition: transform 0.3s ease;
}

.accordian-image.rotate {
  transform: rotate(90deg);
}

.places-of-interest {
  width: 100%;
}

.places-of-interest-resp {
  width: 100%;
  margin-top: 10px;
}

.add-delete-btn-div {
  display: flex;
  align-items: center;
}

.places-btn-div {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.add-new-place-btn {
  background: #2e8b98;
  border-radius: 4px;
  width: 161px;
  border: none;
  color: #fff;
  padding: 8px 20px;
  cursor: pointer;
}

.popular-destinations-div {
  margin-top: 20px;
  margin-bottom: 20px;
}

.popular-destinations-div h3 {
  color: #000;
  font-weight: 500;
}

.places-options {
  display: flex;
  flex-direction: row;
  margin-top: 20px;
  justify-content: start;
  flex-wrap: wrap;
  gap: 1.5%;
}

.place-option {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 2px;
  cursor: pointer;
}

.place-name-option {
  margin-left: 5px;
}

.place-name-text {
  font-size: 15px;
  line-height: 16px;
  text-wrap: wrap;
}

.place-distance-text {
  font-size: 12px;
  color: #00000080;
}

.remove-event-btn {
  background: none;
  border: none;
  color: red;
  cursor: pointer;
}

.add-event-btn {
  background: none;
  border: none;
  color: #2e8b98;
  cursor: pointer;
}

.remove-event-btn:disabled {
  background: none;
  border: none;
  color: #aaa;
  cursor: pointer;
}

.add-event-btn:disabled {
  background: none;
  border: none;
  color: #aaa;
  cursor: pointer;
}

.event-btn-div {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  width: 100%;
}

.property-name-heading,
.pricing-amount {
  color: #000;
}

.property-info {
  display: flex;
  flex-direction: row;
  max-height: 300px;
  justify-content: space-between;
}

.property-img-obj {
  height: 75%;
  border-radius: 10px;
}

.property-img-div {
  flex: 2;
  overflow: hidden;
}

.property-details {
  flex: 3;
  display: flex;
  flex-direction: column;
  color: #000;
}

.property-pricing {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: end;
  padding-right: 20px;
  max-height: 250px;
}

button:disabled {
  background-color: #aaa;
}

.pricing-value {
  color: #000;
}

.facility-div {
  display: flex;
  gap: 3px;
  align-items: center;
  justify-content: center;
}

.facility-name {
  font-size: small;
}

.facilities-div {
  text-decoration: none;
  display: flex;
  list-style-type: none;
  gap: 10px;
}

.facility-item {
  display: inline-block;
  flex-direction: row;
  gap: 10px;
  align-items: center;
  text-align: center;
}

.room-name-div {
  flex: 1;
  text-align: center;
}

.room-facilities-div {
  flex: 3;
  text-align: center;
}

.room-count-div {
  flex: 1;
}

.room-name-text {
  color: #000;
}
.buttons-holder-edit {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 20px;
  button {
    padding: 3px 0px;
  }
}
.property-listing-header-remove-btn {
  display: inline;
  border-radius: 5px;
  padding: 10px 25px;
  cursor: pointer;
  background: rgb(236, 68, 68);
  color: #fff;
  border: none;
  margin-left: 10px;
}

.arrow-up-down-btn {
  background: none;
  border: none;
  color: #2e8098;
  font-size: medium;
  font-weight: 600;
}

.chips-section {
  display: flex;
  gap: 10px;
  align-items: center;
  flex-wrap: wrap;
}

.meal-room-wrapper {
  display: flex;
  flex-direction: column;
  gap: 12px;
}
.share-save-wrapper {
  display: flex;
  align-items: center;
  gap: 14px;
}

@media screen and (max-width: 1023px) {
  .inner-heading-div {
    flex-direction: column;
    gap: 12px;
  }
  .header-btn-grp {
    width: 100%;
  }
  .cityname-itinerary {
    width: 120px;
  }
  .nights-itinerary {
    width: 120px;
  }
  .dialog-login {
    padding: 10px !important;
  }
  .new-txt {
    font-size: 18px !important;
  }
}

@media screen and (min-width: 1024px) {
  .city-distance-div {
    flex-shrink: 0;
    min-width: 150px;
    // flex-grow: 1;
  }
  .dialog-login {
    max-width: 100% !important;
    padding: 60px !important;
  }
}

@media screen and (min-width: 426px) {
  .non-mobile-dragger {
    display: block;
  }
}

@media screen and (max-width: 767px) {
  .meal-room-wrapper {
    width: 100%;
  }
  .city-input-div {
    width: 100%;
  }
  .cities-div {
    gap: 12px;
    justify-content: flex-start;
  }
  .ptv-city-list {
    width: 100%;
  }
  .from-to-city-div {
    width: 100%;
    height: auto;
    gap: 12px;
  }
  .from-to-div {
    justify-content: flex-start;
    gap: 12px;
  }
  .add-new-place-btn {
    width: 100%;
  }
  .event-btn-div {
    justify-content: flex-end;
  }
  .place-to-visit-item {
    flex-direction: column;
    gap: 18px;
  }
  .accordian-header-input {
    margin-left: 10px;
    border-radius: 5px;
    border: 1px solid #00000033;
    font-weight: 600;
    width: 100%;
  }
  // .footer-btn-div {
  //   flex-direction: column;
  //   gap: 4 * 4px;
  // }
  .stays-places-to-visit-heading h1 {
    font-size: 18px;
  }
  .stays-places-to-visit-heading p {
    line-height: 20px;
  }
  .header-btn-grp {
    width: 100%;
    padding: 12px 0px;
    flex-direction: column;
    gap: 16px;
    align-items: normal;
  }
  .dates-people-selection-div {
    flex-direction: column;
  }
  .itinerary-planning-div {
    flex-direction: column;
  }
  .arrival-departure-input-div {
    flex-direction: column;
    gap: 12px;
  }
  .arrival-departure-input-section {
    flex-direction: column;
    gap: 14px;
    button {
      width: 100%;
    }
  }
  .departure-input-section,
  .arrival-input-section {
    width: 100%;
    gap: 10px;
  }
  .arrival-destination-div-label {
    font-size: 12px;
    width: 80px;
    text-align: start;
  }
  .cities-input-section {
    flex-direction: column;
    align-items: flex-start;
    // justify-content: flex-start;
    width: 100%;
  }
  .cityname-itinerary {
    // width: 110px;
    width: calc(100% - 10px);
    font-size: 12px;
  }
  .nights-itinerary {
    width: 80px;
    font-size: 12px;
    // width: calc(100% - 20px);
  }
  .itinerary-planning-cities,
  .itinerary-planning-distances {
    padding: 15px;
  }
  .transfers-itinerary {
    width: 100%;
  }
  .city-distance-div * {
    font-size: 10px;
    flex-grow: 1;
    width: 90px;
    max-width: 140px;
  }

  .city-distance-transport-div {
    flex-grow: 1;
  }
  .accordian-header-div {
    flex-direction: column;
    gap: 12px;
    align-items: flex-start;
    width: 100%;
  }
  .accordian-header-input {
    margin-left: 0px;
    width: calc(100% - 20px);
  }
  .places-to-visit-section {
    padding: 15px;
  }
  .accordian-header {
    align-items: flex-start;
    width: 100%;
  }
  .places-of-interest-div {
    width: 100%;
  }
  .places-btn-div {
    flex-direction: row;
    gap: 24px;
  }
  .ptv-time-from,
  .ptv-time-to {
    width: 100%;
  }
  .from-to-input-div {
    width: 100%;
  }
  .cities-label {
    flex-shrink: 0;
  }
  .basic-multi-select {
    width: 100%;
  }
  .select-dates-wrapper {
    flex-direction: column;
    gap: 14px;
  }
  .add-destination-div {
    width: 100%;
    flex-direction: column;
    gap: 12px;
  }
  .add-destination-btn,
  .suggest-btn {
    width: 100%;
    padding: 12px 25px;
  }
  .people-selection-div {
    justify-content: flex-start;
  }
  .ci-header-section {
    padding: 15px 0px;
  }
}

@media screen and (max-width: 425px) {
  .mobile-dragger {
    display: block;
  }
  .mobile-holder {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
  }
}
.mobile-view {
  display: none;
}

.desktop-view {
  display: block;
  margin-bottom: 1.5%;
}
.wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.wrapper-none {
  justify-content: flex-end;
}
@media screen and (max-width: 1023px) {
  .mobile-view {
    margin-top: 3.5%;
    display: block;
  }
  .desktop-view {
    display: none;
  }
}
@media screen and (max-width: 500px) {
  .wrapper {
    flex-direction: column-reverse;
  }
}

.error-blk {
    max-width: 735px;
    margin: 0px auto;
    color: #ffffff;
    padding: 40px 0;
}

.error-blk .image {
    width: 256px;
    margin: 0 auto 40px;
}

.error-blk .head {
    font-weight: 500;
    font-size: 60px;
    line-height: 70px;
    text-align: center;
    margin-bottom: 15px;
}

.error-blk .sub-head {
    font-weight: 500;
    font-size: 26px;
    line-height: 50px;
    text-align: center;
    margin-bottom: 15px;
}

.error-blk .content {
    font-weight: 400;
    font-size: 14px;
    line-height: 27px;
    text-align: center;
    width: 400px;
    margin: 0 auto;
}

.error-blk .content a {
    color: #67E1F2;
}
.my-booking-blk {
    overflow: hidden;
}

.my-booking-section {
    overflow: hidden;
    border: 1px solid #F8F8F8;
    border-radius: 10px;
    margin-top: 30px;
}

.my-booking-section .my-booking {
    background: #F8F8F8;
    border-radius: 10px;
    overflow: hidden;
}

.my-booking-section .my-booking .image {
    border-radius: 10px;
    width: 255px;
    overflow: hidden;
    float: left;
}

.my-booking-section .my-booking .image img {
    min-height: 204px;
    max-height: 204px;
}

.my-booking-section .my-booking .content {
    width: calc(100% - 280px);
    float: left;
    margin-left: 25px;
    font-weight: 600;
    font-size: 16px;
    line-height: 19px;
    color: #111111;
    padding: 10px 0;
}

.my-booking-section .my-booking .content .subhead {
    color: rgba(17, 17, 17, 0.6);
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
}

.my-booking-section .my-booking .content .head {
    font-weight: 600;
    font-size: 22px;
    line-height: 33px;
}

.my-booking-section .my-booking .content .amount {
    color: rgba(17, 17, 17, 0.7);
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    margin-bottom: 12px;
}

.my-booking-section .my-booking .content .guests {
    font-weight: 600;
    font-size: 14px;
    line-height: 21px;
}

.my-booking-section .my-booking .content .number {
    font-weight: 600;
    font-size: 16px;
    line-height: 19px;
}

.my-booking-section .my-booking .content .btns {
    margin-top: 15px;
}

.my-booking-section .my-booking .content .btns div {
    background: #FFFFFF;
    border: 1px solid #2E8B98;
    border-radius: 4px;
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    text-align: center;
    color: #2E8B98;
    padding: 6px 15px;
    display: inline-block;
    vertical-align: middle;
    cursor: pointer;
}

.my-booking-section .my-booking .content .btns div.modify:hover,
.my-booking-section .my-booking .content .btns div.cancel:hover {
    background: #2E8B98;
    border: 1px solid #2E8B98;
    color: #ffffff;
}

.my-booking-section .my-booking .content .btns div.modify,
.my-booking-section .my-booking .content .btns div.cancel {
    margin-right: 10px;
    margin-top: 10px;
}

.my-booking-section .my-booking .content .btns div.modify span.icon {
    background: url("/assets/images/modify-icon.png") no-repeat center center / cover;
    width: 15px;
    height: 15px;
    display: inline-block;
    vertical-align: middle;
    margin-right: 3px;
}

.my-booking-section .my-booking .content .btns div.modify:hover span.icon {
    background: url("/assets/images/modify-icon-hover.png") no-repeat center center / cover;
}

.my-booking-section .booking-id {
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    color: #727272;
    padding: 8px 0 8px 10px;
}

.my-booking-section .booking-id span {
    color: #000000;
    font-weight: 500;
}

.pop-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background: rgba(0, 0, 0, 0.5);

    .popup {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        background: #fff;
        padding: 15px;
        width: 320px;
        border-radius: 10px;
        color: #151515;
        font-size: 16px;
        line-height: 18px;
        font-weight: 400;

        .heading {
            font-size: 20px;
            line-height: 24px;
            font-weight: 600;
            position: relative;
            border-bottom: 1px solid #ccc;
            padding-bottom: 10px;
            margin-bottom: 10px;

            span {
                position: absolute;
                right: 0px;
                top: 0px;
                cursor: pointer;
            }
        }

        .description {
            text-align: center;

            .button {
                display: inline-block;
                margin: 0 auto;
                padding: 10px 20px;
                border-radius: 10px;
                border: 1px solid #151515;
                margin-top: 15px;
                cursor: pointer;

                &.mr10 {
                    margin-right: 10px;
                }
            }
        }
    }
}

.my-booking-blk .info-txt,
.info-txt.booking {
    color: #000;
}
.discover-section {
    // background: url("/assets/images/discover-bg.png") no-repeat center center / cover;
    margin: 50px 0;
    padding: 50px 0;
    position: relative;
}

.discover-section .background-overlay {
    border-radius: 0;
    bottom: 0;
}

.discover-left {
    float: left;
    width: 35%;
}

.discover-left .heading {
    font-weight: 500;
    font-size: 30px;
    line-height: 45px;
    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.discover-left p {
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    margin-top: 80px;
}

.discover-right {
    float: right;
    width: 40%;
}

.discover-block {
    margin-bottom: 30px;
}

.discover-block .blk {
    position: relative;
}

.discover-block .blk .play-text {
    position: absolute;
    bottom: 50px;
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
}

.discover-block .blk .play-text span {
    display: inline-block;
    vertical-align: middle;
}

.discover-block .blk .play-text .play {
    cursor: pointer;
    margin: 0 10px 0 40px;
}

.discover-block .outer-text {
    // margin-left: 13px;
    // margin-top: -20px;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
}

.background-overlay.discover-video {
    top: 2px;
    height: calc(100% - 10px);
    left: 2px;
    border-radius: 15px;
}

.discover-left .green-btn {
    cursor: pointer;
    margin-top: 200px;
}

@media screen and (min-width: 100px) and (max-width: 767px) {
    .discover-left {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .discover-left .green-btn {
        cursor: pointer;
        margin: 30px 0px;
    }

    .discover-left .green-btn a,
    .discover-left .green-btn span {
        padding: 8px 40px !important;
    }

}

.discover-left .green-btn a,
.discover-left .green-btn span {
    background: #2E8B98;
    border-radius: 6px;
    font-weight: 500;
    font-size: 12px;
    line-height: 24px;
    text-transform: uppercase;
    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    padding: 8px 50px;
    display: inline-block;
    color: #ffffff;
}
.deals-section {
    position: relative;
    margin: 0px 0 50px;
}

.deals-section .heading {
    font-weight: 500;
    font-size: 25px;
    line-height: 38px;
    margin-bottom: 10px;
    position: relative;
    overflow: hidden;
}

.deals-section .heading .float-left {
    float: left;
}

.deals-section .heading .card-tray-heading {
    font-weight: 500;
    font-size: 25px;
    line-height: 38px;
}

.deals-section .heading span {
    color: #67E1F2;
}

.deals-section .heading span.view-all {
    position: absolute;
    right: 155px;
    top: 6px;
    font-weight: 400;
    font-size: 20px;
    line-height: 30px;
}

.deals-section .heading span.view-all.rt-0 {
    right: 0;
}

.deals-section .heading span.view-all a {
    color: #3DD2E7;
}

.deals-gallery {
    position: relative;
    padding: 0 10px;
    text-align: center;
}

.deals-gallery .background-overlay {
    height: 30%;
}

.deals-gallery ul {
    list-style: none;
    margin: 0;
    padding: 0;
}

.deals-gallery ul li {
    display: inline-block;
    vertical-align: top;
    margin: 0 10px;
    width: 240px;
}

.deals-gallery.discover ul li {
    width: 32%;
}

.deals-gallery.discover ul li:first-child {
    margin-left: 0;
}

.deals-gallery.discover ul li:last-child {
    margin-right: 0;
}

.deals-gallery.discover {
    text-align: left;
    padding: 0;
}

.deals-gallery .section .owl-stage {
    left: -50px;
}

.deals-gallery .section {
    overflow: inherit;
}

.deals-gallery .section .owl-theme .owl-nav {
    position: absolute;
        top: -50px;
        right: 0;
        margin: 0;
}

.owl-stage {
    display: flex !important;
}

.owl-item {
    background: #222 !important;
    border: 1px solid #2e2c2c !important;
    border-radius: 15px !important;
}

// .owl-item :active {
//     background: #222 !important;
//     border: 1px solid #363333 !important;
//     border-radius: 15px !important;
// }

.deals-gallery .section .owl-carousel .owl-nav button.owl-prev {
    background: url('../../assets/images/previous-icon.png') no-repeat center center / cover;
    width: 60px;
    height: 30px;
}

.deals-gallery .section .owl-carousel .owl-nav button.owl-next {
    background: url('../../assets/images/next-icon.png') no-repeat center center / cover;
    width: 60px;
    height: 30px;
}

.deals-gallery .section .owl-carousel .owl-nav button.owl-prev.disabled {
    background: url('../../assets/images/previous-disabled-icon.png') no-repeat center center / cover;
    width: 60px;
    height: 30px;
}

.deals-gallery .section .owl-carousel .owl-nav button.owl-next.disabled {
    background: url('../../assets/images/next-disabled-icon.png') no-repeat center center / cover;
    width: 60px;
    height: 30px;
}

.deals-gallery .section .owl-carousel .owl-nav button.owl-prev span,
.deals-gallery .section .owl-carousel .owl-nav button.owl-next span {
    display: none;
}

.card-background {
    background: #222222;
    border-radius: 15px;
    // border: 1px solid #363333;
}

.card-background .p-10 {
    padding: 0 10px;
}

.card-background .pb-10 {
    padding-bottom: 10px;
}
.group-booking-banner {
    width: auto;
    overflow: hidden;
    position: relative;
}

.group-booking-banner .background-overlay {
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.2) 0%, rgba(0, 0, 0, 0.5) 100%);
    position: absolute;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 15px;
    bottom: 0;
}

.group-booking-banner img {
    width: 100%;
    display: block;
}

.breadcrum.group-booking {
    position: absolute;
    top: 70px;
}

.breadcrum.group-booking ul li,
.breadcrum.group-booking ul li a {
    // color: #111111;
}

.group-booking-blk {
    max-width: 730px;
    margin: 0 auto;
    padding: 40px 0 0;
    // color: #FFFFFF;
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
}

.group-booking-blk .head {
    font-weight: 500;
    font-size: 35px;
    line-height: 70px;
}

.group-booking-blk p.content {
    margin: 10px 0 20px;
}

.align *{
    text-align: justify;
}
.group-booking-blk .subhead {
    font-weight: 500;
    font-size: 28px;
    line-height: 42px;
}

.group-booking-blk .group-booking-list {
    font-weight: 400;
    font-size: 13px;
    line-height: 28px;
}

.group-booking-blk .group-booking-list {
    padding: 0 0 0 20px;
    margin: 0;
    color: #ffffff;
    overflow: hidden;
}

.group-booking-blk .group-booking-list.left {
    float: left;
    width: 47%;
}

.group-booking-blk .group-booking-list li {
    padding: 5px 0;
}

.group-booking-list-blk {
    overflow: hidden;
    margin: 30px 0;
}

.group-booking-blk .form-fields {
    overflow: hidden;
    margin: 20px 0 0;
}

.group-booking-blk .form-fields .form {
    width: 33.33%;
    float: left;
    margin-top: 30px;
}

.group-booking-blk .form-fields .form.w100 {
    float: none;
    width: 100%;
    margin-top: 30px;
    overflow: hidden;
    clear: both;
}

.group-booking-blk .form-fields .clear {
    clear: both;
}

.group-booking-blk .form-fields .form label {
    font-weight: 700;
    font-size: 14px;
    line-height: 17px;
    color: rgba(255, 255, 255, 0.9);
    display: block;
    margin: 0 0 15px 20px;
}

.group-booking-blk .form-fields input,
.group-booking-blk .form-fields textarea {
    background: #202020;
    box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.16);
    border-radius: 6px;
    resize: none;
    border: 1px solid #202020;
    font-weight: 500;
    font-size: 13px;
    line-height: 16px;
    color: #FFFFFF;
    padding: 15px 20px;
}

.group-booking-blk .form-fields textarea {
    width: calc(100% - 40px);
}

.group-booking-blk .form-fields .button-form {
    margin-top: 50px;
    text-align: center;
}

.group-booking-blk .form-fields .button-form input.reset {
    border: 1px solid rgba(255, 255, 255, 0.2);
    box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.16);
    border-radius: 6px;
    margin-right: 15px;
    font-weight: 700;
    font-size: 16px;
    line-height: 19px;
    color: #ffffff;
    padding: 15px 80px;
    cursor: pointer;
}

.group-booking-blk .form-fields .button-form input.submit {
    background: #2E8B98;
    box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.16);
    border-radius: 6px;
    font-weight: 700;
    font-size: 16px;
    line-height: 19px;
    color: #111111;
    padding: 15px 80px;
    cursor: pointer;
}

.group-booking-blk .testimonial-section {
    margin-bottom: 50px;
}

.our-client-love-us {
    margin-bottom: 30px;
    overflow: hidden;
    margin-top: 20px;
}

.our-client-love-us ul {
    list-style: none;
    margin: 0;
    padding: 0;
}

.our-client-love-us ul li {
    width: calc(33.33% - 14px);
    float: left;
    padding: 0 7px;
}

.our-client-love-us ul li:first-child{
    // padding-left: 0;
}

.our-client-love-us ul li:last-child {
    // padding-right: 0;
}

.mb0 {
    margin-bottom: 0;
}

.tac {
    text-align: center;
}

.group-booking-blk .form-fields.tac span {
    color: #1AB2D2;
}

.group-booking.gray-bg {
    padding: 0 0 50px 0;
}

.group-booking-blk.partners .head{
    color: #000000;
}
.property-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background: rgba(40, 40, 40, 0.5);
  z-index: 30;
}

.confirmation-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background: rgba(40, 40, 40, 0.9);
  z-index: 3;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.confirmation-cancel {
  display: flex;
  justify-content: end;
}
.loader-holder {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 40vh;
}

.confirmation-cancel-btn {
  background-color: #262626;
  color: wheat;
  border: none;
  cursor: pointer;
}

.confirmation-okay-btn {
  background: #2e8b98;
  border: none;
  border-radius: 6px;
  padding: 10px 13px;
  text-align: center;
  cursor: pointer;
}

.confirmation-content {
  height: 70%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.confirmation-content-text {
  text-align: center;
  color: white;
}

.content-text-first {
  font-weight: 500;
  line-height: 30px;
  font-size: 20px;
}

.content-text-second {
  font-weight: 300;
  line-height: 17px;
  font-size: 12px;
}

.confirmation-okay {
  display: flex;
  justify-content: center;
}

.confirmation-popup {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: #262626;
  padding: 15px;
  color: #000;
  width: 30%;
  height: 30%;
  border-radius: 10px;
}

.property-listing-popup {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: #262626;
  padding: 15px;
  color: #000;
  width: 80%;
  height: 80%;
  border-radius: 10px;
}

.overlay-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  // height: 4%;
  margin-bottom: 10px;
  // padding: 0px 10px;
}

.overlay-header-text {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  // width: 26%;
}

.overlay-header-search-close {
  // width: 25%;
  display: flex;
  flex-direction: row;
  justify-content: end;
  gap: 5%;
}

.hotel-search-txt {
  padding: 15px;
  width: 90%;
  border-radius: 20px;
  border: 1px solid black;
}

.close-btn {
  padding: 0 10px 0 10px;
  background: none;
  color: #000;
  border: none;
  font-weight: 800;
  font-size: 20px;
  border-radius: 6px;
}

.overlay-header-txt {
  color: #fff;
  font-size: 140%;
  font-weight: 700;
}

.overlay-header-city {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  color: #000;
}

.overlay-heading-city {
  color: #000;
}

.overlay-content {
  height: 90%;
  display: flex;
  flex-direction: row;
  padding: 0.5%;
  gap: 1%;
}

.filter-div {
  background: #1f1f1f;
  position: relative;
  // width: 20%;
  border-radius: 10px;
  overflow-y: auto;
  width: 300px;
  flex-shrink: 0;
}

.results-div {
  background-color: #fff;
  width: 80%;
}

.results-div-content {
  background-color: #fff;
  border-radius: 10px;
  overflow-y: scroll;
  height: 95%;
}

.property-filter-by {
  background: rgba(34, 34, 34, 0.6);
  border-radius: 10px;
  width: 94%;
  padding: 30px 0;
  margin: 3%;
}

.property-filter-by .head-container {
  padding: 0px 25px 0 0px;
}

.property-filter-by .head-container .head {
  font-weight: 400;
  font-size: 20px;
  line-height: 30px;
  color: #ffffff;
  padding: 0 25px;
  margin-bottom: 15px;
  position: relative;
}

.property-listing-details-text {
  margin-top: 15px;
}

.place-of-interest-listing-details-text {
  margin-top: 15px;
  width: 60%;
}

.place-of-interest-time {
  font-weight: 800;
}

.property-filter-by .acc-blk {
  border-bottom: 1px solid rgba(77, 77, 77, 0.5);
  padding: 15px 25px;
}

.property-filter-by .acc-blk .acc-head {
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: #ffffff;
  position: relative;
}

.property-filter-by .acc-blk .acc-head span.arrow,
.property-filter-by .head-container .head span.arrow {
  position: absolute;
  right: 0px;
}

.property-filter-by .acc-blk .acc-head span.arrow.active,
.property-filter-by .head-container .head span.arrow.active {
  transform: rotate(180deg);
  top: 2px;
}

.property-filter-by .acc-blk .acc-content {
  overflow: hidden;
  margin-top: 20px;
  color: #fff;
}

.property-filter-by .acc-blk .acc-content ul {
  margin: 0;
  padding: 0;
}

.property-filter-by .acc-blk .acc-content ul li {
  list-style: none;
  font-weight: 400;
  font-size: 12px;
  line-height: 21px;
  margin-bottom: 15px;
}

.property-filter-by .acc-blk .acc-content ul li a {
  display: block;
  color: #ffffff;
}

.property-filter-by .acc-blk .acc-content ul li span.checkbox,
.property-filter-by .acc-blk .acc-content ul li span.expand {
  background: url("../../assets/images/checkbox.png") no-repeat center center /
    cover;
  width: 20px;
  height: 20px;
  display: inline-block;
  vertical-align: middle;
  margin-right: 10px;
  cursor: pointer;
}

.property-filter-by .acc-blk .acc-content ul li span.expand {
  background: url("../../assets/images/plus.png") no-repeat center center /
    cover;
}

.property-filter-by .acc-blk .acc-content ul li span.checkbox.active {
  background: url("../../assets/images/checkbox-active.png") no-repeat center
    center / cover;
}

.property-filter-by .acc-blk .acc-content ul li span.expand.active {
  background: url("../../assets/images/minus.png") no-repeat center center /
    cover;
}

.property-filter-by .apply-btn {
  background: #2e8b98;
  border-radius: 6px;
  padding: 8px 10px;
  margin: 25px 25px 0;
  text-align: center;
  cursor: pointer;
}

.property-filter-by .acc-blk .acc-content ul li ul {
  margin-left: 35px;
}

.property-filter-by .acc-blk .acc-content ul li ul li {
  margin: 10px 0 0 0;
  position: relative;
}

.property-filter-by .acc-blk .acc-content ul li ul li span.count {
  position: absolute;
  right: 0px;
}

.property-listing-section {
  margin-bottom: 20px;
  background: #1f1f1f;
  color: #fff;
  padding: 15px;
  border-radius: 6px;
  gap: 2%;
  display: flex;
  flex-direction: row;
  align-items: center;
  min-height: 180px;
  height: auto;
}

.property-listing-img-div {
  width: 280px;
  height: 170px;
  display: flex;
  flex-direction: row;
  justify-content: start;
}

.property-listing-div {
  width: 70%;
  min-height: 170px;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.property-listing-img {
  height: 100%;
  width: 100%;
  border-radius: 10px;
}

.property-listing-header-div {
  display: flex;
  flex-direction: row;
  align-items: baseline;
  justify-content: space-between;
  width: 100%;
  flex-wrap: wrap;
  overflow-wrap: anywhere;
  gap: 12px;
}

.property-listing-header-txt {
  font-weight: 600;
}

.property-listing-header-btn {
  display: inline;
  border-radius: 5px;
  padding: 10px 25px;
  cursor: pointer;
  color: #fff;
  border: none;
}

.selected-btn {
  background: red;
}

.unselected-btn {
  background: #2e8b98;
}

.property-facilities ul.inline-block {
  padding: 0;
}

.property-facilities ul.inline-block li {
  display: inline-block;
  vertical-align: middle;
  margin-bottom: 15px;
  margin-right: 15px;
  font-size: x-small;
}

.property-facilities ul.inline-block li {
  width: inherit;
  margin-right: 25px;
}

.property-facilities ul.inline-block li span {
  display: inline-block;
  vertical-align: middle;
  margin-right: 10px;
}

.overlay-pagination-holder {
  text-align: center;
  font-weight: 700;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  font-size: 14px;
  color: #000;
  border-radius: 10px;
  padding: 8px 10px;
}

@media screen and (min-width: 100px) and (max-width: 767px) {
  .overlay-pagination-holder {
    flex-direction: column;
    align-items: normal;
  }

  .pagination-right-container {
    padding-top: 5px;
    justify-content: center !important;
    padding-bottom: 15px;
  }
}

.pagination-left-container {
  flex-grow: 1;
  display: flex;
  text-align: center;
  font-weight: 700;
  align-items: center;
  justify-content: space-between;
}

.pagination-right-container {
  flex-grow: 3;
  display: flex;
  text-align: center;
  align-items: center;
  justify-content: end;
  font-size: 12px;
  font-weight: 500;
  color: #fff;
}

.overlay-pagination-holder button {
  border: none;
  cursor: pointer;
  width: 30px;
  height: 30px;
  border-radius: 4px;
}

.overlay-pagination-holder .inactive {
  color: white;
  margin: 10px 0px;
  padding: 8px 5px;
  cursor: pointer;
}

.overlay-pagination-holder .active {
  background-color: #2e8b98;
  color: white;
  padding: 8px 16px;
  border-radius: 50px;
}

.pagination-next {
  background: url("../../assets/images/next-icon.png") no-repeat center center /
    cover;
}

.pagination-prev {
  background: url("../../assets/images/previous-icon.png") no-repeat center
    center / cover;
}

.pagination-prev-disabled {
  cursor: not-allowed !important;
  background: url("../../assets/images/previous-disabled-icon.png") no-repeat
    center center / cover;
}

.pagination-next-disabled {
  cursor: not-allowed !important;
  background: url("../../assets/images/next-disabled-icon.png") no-repeat center
    center / cover;
}

.no-properties-msg {
  width: 100%;
  height: 40%;
  text-align: center;
  font-weight: 500;
}

.places-close-btn {
  // width: 60px;
  padding: 15px;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  border-radius: 100% !important;
}

@media screen and (max-width: 767px) {
  .overlay-pagination-holder {
    flex-direction: column;
    align-items: normal;
  }

  .pagination-right-container {
    padding-top: 5px;
    justify-content: center !important;
    padding-bottom: 15px;
  }
  .overlay-content {
    flex-direction: column;
    gap: 3%;
  }
  .overlay-header {
    gap: 10px;
    flex-direction: column;
  }

  .places-overlay-header-search-close {
    justify-content: space-between !important;
    align-items: center;
    width: 100% !important;
    input {
      width: 70% !important;
    }
    .places-close-btn {
      display: flex !important;
      justify-content: center !important;
      align-items: center !important;
      padding: 16px !important;
    }
  }
  .filter-div {
    width: 100%;
  }
  .property-listing-popup {
    overflow-y: auto;
  }
}
@media screen and (max-width: 768px) {
  .property-listing-section {
    flex-direction: column;
    align-items: flex-start;
  }
  .property-listing-div {
    width: 100%;
    gap: 12px;
  }
  .places-listing-img-div {
    width: 100% !important;
  }
  .property-listing-img-div{
    width: 100% !important;
  }
}

.testimonial-blk .testimonial-blk-left {
    float: left;
    width: 30%;
    position: relative;
    min-height: 160px;
}

.testimonial-blk .testimonial-blk-left .vcenter {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
}

.testimonial-blk .testimonial-blk-right {
    float: right;
    width: 70%;
}

.testimonial-blk .user-pic {
    max-width: 90px;
    margin: 15px auto 15px;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    align-content: center;
}

.testimonial-blk .user-name {
    font-weight: 600;
    font-size: 18px;
    line-height: 30px;
    margin-bottom: 5px;
}

.testimonial-blk .user-designation {
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    // margin-bottom: 25px;
}

.testimonial-blk .user-testimonial {
    font-weight: 600;
    font-size: 20px;
    line-height: 30px;
    padding: 15px 80px;
    position: relative;
    text-align: justify;
}

.testimonial-blk .user-testimonial .top-quotes {
    position: absolute;
    top: 0;
    left: 0;
    background: url("/assets/images/testimonial1.png") no-repeat center center / cover;
    width: 51px;
    height: 49px;
}

.testimonial-blk .user-testimonial .bottom-quotes {
    position: absolute;
    bottom: 0;
    right: 0;
    background: url("/assets/images/testimonial2.png") no-repeat center center / cover;
    width: 51px;
    height: 49px;
}

.testimonial-blk .user-testimonial .testimonial-message {
    height: 180px;
    overflow: auto;
    text-align: justify;
    // padding-right: 20px;
    margin-right: 0;
}

.user-img {
    max-width: 100%;
    height: auto;
    display: initial;
    width: 120px !important;
    border-radius: 15px;
}
.inner-heading .head.text-center {
    text-align: center;
    float: none;
}

.privacy-blk {
    max-width: 800px;
    margin: 0 auto;
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    color: #FFFFFF;
}

.privacy-blk p.bold {
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
}

.privacy-blk p {
    margin-bottom: 30px;
}

.privacy-blk ul {
    margin: 0;
    padding-left: 15px;
}

.privacy-blk ul li {
    margin-bottom: 20px;
}

.consent-popup-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background: rgba(40, 40, 40, 0.5);
    z-index: 3;
}

.consent-popup {
    position: absolute;
    top: 51%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: #161616;
    padding: 20px;
    color: #ffffff;
    width: 40%;
    height: 60%;
    border-radius: 10px;
}

.consent-popup-header {
    text-align: center;
    color: #2E8B98;
}

.consent-popup-footer {
    text-align: center;
}

.consent-popup-body {
    display: flex;
    flex-direction: column;
    margin-top: 30px;
}

.consent-popup-body-toggle {
    margin-top: 12px;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.toggle-switch {
    margin-right: 15px;
}

.save-preferences-btn {
    background: #2E8B98;
    font-size: medium;
    border-radius: 6px;
    padding: 12px;
    margin: 25px 25px 0;
    text-align: center;
    cursor: pointer;
    border: none;
    color: #ffffff;
    margin-bottom: 3px;
}
.stays-section {
  display: flex;
  flex-direction: column;
  color: #000;
  background-color: #ffffff;
  margin-top: 20px;
  margin-bottom: 15px;
  overflow: hidden;
  border: 1.5px solid #5b96a533;
  gap: 25px;
  padding: 24px;
  border-radius: 10px;
  max-width: inherit;
}
.tabs-card-footer-wrapper {
  display: flex;
  flex-direction: column;
  gap: 30px;
}

.pdp-stays-section {
  width: 100%;
}

.stays-section-resp {
  display: flex;
  flex-direction: column;
  color: #000;
  background-color: #ffffff;
  margin-top: 20px;
  margin-bottom: 15px;
  overflow: hidden;
  max-height: auto;
  border: 1.5px solid #5b96a533;
  gap: 35px;
  padding: 24px;
  margin: 20px;
  border-radius: 10px;
}

.room-child-wrapper {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.radio-img-carosel {
  display: flex;
  align-items: center;
  gap: 20px;
}

.terms-and-conditions * {
  text-align: justify;
}

.room-info {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  background-color: #f4fdff;
  padding: 12px;
  border: solid 1px;
  border-radius: 15px;
  border-color: #e0edf0;
  gap: 15px;
}
.roomselection-radiobtn {
  width: 20px;
  // border: 1px solid red;
}
.roomimagecontainer {
  width: 170px;
  // border: 1px solid green;
}

.room-info-resp {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  background-color: #f4fdff;
  padding: 20px;
  border: solid 1px;
  border-radius: 15px;
  border-color: #e0edf0;
  gap: 15px;
}

.room-info-col {
  display: flex;
  flex-direction: column;
  justify-content: left;
  // align-items: flex-start;
  gap: 35px;
  background-color: #f4fdff;
  padding: 20px;
  border: solid 1px;
  border-radius: 15px;
  border-color: #e0edf0;
}

.room-media-gallery {
  display: flex;
  flex-direction: row;
  gap: 20px;
  opacity: 0px;
}
.room-media-gallery-image {
  width: 248px;
  height: 226px;
}
.cancellation-status {
  color: red;
  font-size: 12px;
  font-weight: 600;
  line-height: 12px;
  text-align: center;
}
.room-name-div {
  display: flex;
  flex-direction: row;
  gap: 15px;
}

.room-facilities-div {
  flex: 3;
  text-align: center;
}
.amenites-div {
  display: inline-block;
}
.amenites-div ul li {
  display: inline-block;
}
.room-details-div {
  // display: flex;
  // width: 500px;
  // border: 1px solid red;
  display: flex;
  flex-direction: column;
  gap: 5px;
  flex-grow: 1;
  width: 100%;
  // height: 145px;
}
.cancellation-div {
  width: 400px;
  display: inline-block;
  // border: 1px solid red;
  height: 145px;
  font-size: 12px;
}
.room-header {
  font-size: 16px;
  font-weight: 500;
  line-height: 30px;
  text-align: left;
  margin-bottom: 6px;
}
.room-details-text {
  font-size: 14px;
  font-weight: 400;
  text-align: left;
  margin-bottom: 14px;
  height: 14px;
}
.location-background {
  background-image: url("../../assets/images/gridicons_location.png");
  width: 16px;
  height: 16px;
  background-size: cover; /* Optional: Adjust based on your design */
  background-repeat: no-repeat;
  flex-shrink: 0;
}

.date-background {
  background-image: url("../../assets/images/lets-icons_date-fill.png");
  width: 16px;
  height: 16px;
  background-size: cover; /* Optional: Adjust based on your design */
  background-repeat: no-repeat;
}

.room-count-div {
  flex: 1;
  display: flex;
  flex-direction: row;
  gap: 5px;
  justify-content: space-between;
}

.dropdown-label {
  color: #00000080;
  font-weight: 400;
  font-size: 12px;
  line-height: 24px;
  align-self: center;
}
.room-name-text {
  align-content: center;
  color: #000;
  font-size: 20px;
  font-weight: 500;
  line-height: 30px;
  text-align: left;
}

.property-stay-header-remove-btn {
  display: inline;
  border-radius: 5px;
  padding: 10px 25px;
  cursor: pointer;
  background: rgb(236, 68, 68);
  color: #fff;
  border: none;
  margin-left: 10px;
}

.property-stay-details-location-date {
  display: flex;
  flex-direction: row;
  gap: 15px;
  flex-wrap: wrap;
  align-items: center;
}
.property-stay-details-bubble {
  display: flex;
  flex-direction: row;
  border: solid;
  align-items: center;
  gap: 5px;
  border-width: 1px;
  border-radius: 100px;
  border-color: #0000001a;
  padding: 3px 10px 3px 10px;
  // display: block; // ensures it occupies its own line
  // word-break: break-all; // ensures long text breaks within the word if needed
  white-space: normal; // prevents the text from staying on one line
  overflow-wrap: break-word;
}

.stay-location-date-label {
  font-weight: 500;
  font-size: 13px;
  color: #00000080;
}
.stay-location-date-div {
  font-weight: 500;
  font-size: 13px;
  color: #000000;
  text-wrap: wrap;
}

.property-stay-div {
  display: flex;
  flex-direction: column;
  gap: 12px;
  flex-grow: 2;
  max-width: 100%;
  box-sizing: border-box;
}

.property-stay-div-resp {
  display: flex;
  flex-direction: column;
  gap: 35px;
  flex-grow: 2;
  width: inherit;
}

.property-stay-img-div {
  height: 240px;
}

.property-stay-img-div-resp {
  height: 240px;
  max-width: fit-content;
  margin-left: auto;
  margin-right: auto;
}

.property-stay-img {
  width: 100%;
  height: 100%;
  border-radius: 5px;
  object-fit: cover;
  // width: 356px;
  max-height: 309px;
  border-radius: 8px;
}
.property-stay-thumbnail {
  width: 100%;
  height: 170px;
  // width: 356px;
  margin-bottom: 5px;
  position: relative;
}

.property-stay-section-resp {
  display: flex;
  flex-direction: column;
  background-color: #ffffff;
  gap: 35px;
  width: 100%;
}

.property-stay-section {
  display: flex;
  flex-direction: row;
  background-color: #ffffff;
  gap: 35px;
  justify-content: center;
}

.property-stay-header-div {
  display: flex;
  flex-direction: column;
  gap: 20px;
  align-items: baseline;
  justify-content: space-between;
  width: 100%;
}

.property-stay-content-button {
  display: flex;
  flex-direction: row;
  gap: 32px;
  flex-grow: 2;
}

.property-stay-content-button-resp {
  display: flex;
  flex-direction: row;
  gap: 32px;
  flex-grow: 2;
  width: 100%;
}

.property-stay-description span {
  text-align: justify;
}

.property-stay-description p {
  text-align: justify;
}

.facilities-room-div {
  display: flex;
  flex-wrap: wrap;
  gap: 3px;
  font-size: 12px;
}

.property-stay-description p:nth-child(n + 2) {
  display: none;
}

.meal-wrapper {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.property-stay-header-txt {
  margin-top: 0;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  .title {
    font-size: 25px;
    font-weight: 500;
    color: #000000;
  }
}

.city-date-div {
  font-size: 12px;
  font-weight: 600;
  line-height: 16px;
}

.property-stay-header-btn {
  width: 89px;
  height: 37;
  display: inline;
  border-radius: 4px;
  padding: 8px 16px 8px 16px;
  cursor: pointer;
  background: #e57d00;
  color: #fff;
  border: none;
}

// .room-type-dropdown {
//   padding: 5px 10px;
//   background: white;
//   border-radius: 4px;
//   font-weight: 600;
//   font-size: 13px;
//   line-height: 20px;
//   border: 1px solid #00000033;
//   color: #000;
//   width: 211px;
// }

.room-type-dropdown {
  border-radius: 4px;
  border-color: #00000033;
  background-color: #ffffff;
  padding: 13px 20px 13px 20px;
  width: 230px !important;
  height: 50px;
  font-weight: 500;
  appearance: none;
  font-size: 12px;
  background: url("../../assets/images/down-arrow.png") no-repeat;
  background-position: 196px;
}

@media screen and (min-width: 768px) and (max-width: 1023px) {
  .property-stay-header-txt {
    .title {
      font-size: 20px;
    }
  }
}
@media screen and (max-width: 767px) {
  .stays-section {
    padding: 18px;
  }
  .property-stay-section {
    flex-direction: column;
  }
  .property-stay-header-txt {
    .title {
      font-size: 16px;
    }
  }
  .property-stay-img-div {
    flex-grow: 1;
  }
  .property-stay-thumbnail {
    width: 100%;
    .property-stay-img {
      width: 100%;
    }
  }
  .property-stay-header-btn {
    padding: 8px 16px 8px 16px;
  }
  .stay-location-date-div {
    font-size: 12px;
  }
  .room-info {
    flex-direction: column;
  }
  .meal-wrapper {
    width: 100%;
  }
  .radio-img-carosel {
    width: 100%;
  }
  .roomimagecontainer {
    width: 100%;
  }
  .room-type-image {
    height: 170px !important;
  }
  .nav-left {
    height: 170px;
  }
  .nav-right {
    height: 170px;
    top: 134px;
  }
  .room-type-dropdown {
    width: 100% !important;
    background-position: calc(100% - 20px) center;
  }
  .dropdown-label {
    width: 70px;
  }
}
.roomList {
  overflow-y: scroll;
}
.stays-dropdown-div {
  display: flex;
  flex-direction: column;
}

.stays-item {
  flex: 0 0 calc(50% - 10px);
}
.stays-item div {
  font-size: 14px;
  font-weight: 500;
  line-height: 21px;
  text-align: left;
}

.overlay-pagination-holder {
  text-align: center;
  font-weight: 700;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  font-size: 14px;
  color: #000;
  border-radius: 10px;
  padding: 8px 10px;
}

.property-facilities-stays {
  display: grid;
  grid-gap: 10px;
  grid-template-columns: auto auto;
  flex-grow: 1;
}
.property-facilities ul {
  margin: 0;
}
.property-facilities li {
  margin-bottom: 0 !important;
}

.property-facilities {
  display: flex;
}

.property-availability-msg {
  color: red;
}

.empty-item {
  flex: 0 0 calc(50% - 10px); /* Placeholder for odd item to maintain layout */
  visibility: hidden;
}

@media screen and (min-width: 100px) and (max-width: 767px) {
  .overlay-pagination-holder {
    flex-direction: column;
    align-items: normal;
  }

  .pagination-right-container {
    padding-top: 5px;
    justify-content: center !important;
    padding-bottom: 15px;
  }
}

.pagination-left-container {
  flex-grow: 1;
  display: flex;
  text-align: center;
  font-weight: 700;
  align-items: center;
  justify-content: space-between;
}

.pagination-right-container {
  flex-grow: 3;
  display: flex;
  text-align: center;
  align-items: center;
  justify-content: end;
  font-size: 12px;
  font-weight: 500;
}

.overlay-pagination-holder button {
  border: none;
  cursor: pointer;
  width: 30px;
  height: 30px;
  border-radius: 4px;
}

.overlay-pagination-holder .inactive {
  color: white;
  margin: 10px 0px;
  padding: 8px 5px;
  cursor: pointer;
}

.overlay-pagination-holder .active {
  background-color: #2e8b98;
  color: white;
  padding: 8px 16px;
  border-radius: 50px;
}

.pagination-next {
  background: url("../../assets/images/next-icon.png") no-repeat center center /
    cover;
}

.pagination-prev {
  background: url("../../assets/images/previous-icon.png") no-repeat center
    center / cover;
}

.pagination-prev-disabled {
  cursor: not-allowed !important;
  background: url("../../assets/images/previous-disabled-icon.png") no-repeat
    center center / cover;
}

.pagination-next-disabled {
  cursor: not-allowed !important;
  background: url("../../assets/images/next-disabled-icon.png") no-repeat center
    center / cover;
}

.no-properties-msg {
  width: 100%;
  height: 40%;
  text-align: center;
  font-weight: 500;
}

.accordion {
  border: 1px solid #ccc;
  margin-bottom: 10px;
}

.accordionHeader {
  display: flex;
  align-items: center;
  padding: 10px;
  cursor: pointer;
}

.icon {
  margin-right: 10px;
  width: 24px;
  height: 24px;
}

.title {
  margin: 0;
}

.nav-left {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  left: 0;
  // top: 0;
  width: 40px;
  height: 136px;
  background: linear-gradient(
    90deg,
    rgba(0, 0, 0, 0.4) 0%,
    rgba(40, 40, 40, 0) 100%
  );
  border-radius: 10px 0px 0px 10px;

  .left-active,
  .left-disabled {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 21px;
    height: 20px;
    transform: translate(-50%, -50%);
    background: url("../../assets/images/enable-left.png") no-repeat center
      center / cover;
    cursor: pointer;
  }

  .left-disabled {
    background: url("../../assets/images/disable-left.png") no-repeat center
      center / cover;
    // top: 46%;
    // left: 41%;
  }
}

.nav-right {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  right: 0;
  translate: 0 -135px;
  // top: 1%;
  height: 136px;
  width: 40px;
  background: linear-gradient(
    90deg,
    rgba(40, 40, 40, 0) 0%,
    rgba(0, 0, 0, 0.4) 100%
  );
  border-radius: 0px 10px 10px 0px;

  .right-active,
  .right-disabled {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 21px;
    height: 20px;
    transform: translate(-50%, -50%);
    background: url("../../assets/images/enable-right.png") no-repeat center
      center / cover;
    cursor: pointer;
  }

  .right-disabled {
    background: url("../../assets/images/disable-right.png") no-repeat center
      center / cover;
    // top: 46%;
    // left: 41%;
  }
}

.room-type-image {
  border-radius: 10px;
  height: 136px !important;
  width: 100%;
  display: block;
}
.image {
  // border-radius: 10px;
  overflow: hidden;
  position: relative;
  flex-grow: 2;
  // width: 165px;
}
.roomtype-image {
  border-radius: 10px;
  overflow: hidden;
  position: relative;
  flex-grow: 2;
  width: 165px;
}

.image-container {
  position: relative;
  display: inline-block;
}

.carousel-toggle-button {
  position: absolute;
  bottom: 10px;
  left: 50%;
  transform: translateX(-50%);
  // transform: translate(130%, -140%);
  // z-index: 1;
  border-radius: 4px;
  padding: 8px 16px 8px 16px;
  color: #ffffff;
  cursor: pointer;
  // font-size: 22px;
  background: linear-gradient(
    0deg,
    rgba(21, 21, 21, 0.6),
    rgba(21, 21, 21, 0.6)
  );

  border: none;
}

.carousel-container {
  position: relative;
  // bottom: -100px; /* adjust this value based on your design */
  // left: 50%;
  // transform: translateX(-50%);
  // background-color: white;
  // padding: 10px;
  // border: 1px solid #ccc;
  // z-index: 1;
}

.carousel-images {
  display: flex;
  flex-direction: row;
  gap: 6px;
  // justify-content: space-between;
  overflow-x: hidden;
  // width: 356px;
  width: 100%;
}

.carousel-images img {
  width: 33%;
  height: 70px;
}

.carousel-arrow {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background-color: rgba(0, 0, 0, 0.5);
  border-radius: 100%;
  // color: white;
  border: none;
  cursor: pointer;
  padding: 6px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.carousel-arrow.left {
  left: 5px;
}

.carousel-arrow.right {
  right: 5px;
}

@media screen and (max-width: 1023px) {
  .property-stay-details-bubble {
    flex-wrap: wrap;
  }
}

.packages-section {
    width: auto;
    overflow: hidden;
}

.packages-section .heading {
    font-weight: 500;
    font-size: 25px;
    line-height: 30px;
    // text-align: center;
    margin-bottom: 10px;
}

.packages-section .heading span {
    color: #67E1F2;
}

.package-block {
    margin-bottom: 15px;
    overflow: hidden;
}

.package-block .background-overlay {
    height: 30%;
}

.package-block .w70 {
    width: calc(70% - 15px);
    float: left;
    margin-right: 15px;
}

.package-block .w30 {
    width: 30%;
    float: left;
}

.package-block .image {
    position: relative;
}

.package-block .image .imageStyle {
    width: 100%;
    min-height: 250px;
    max-height: 250px;
    display: block;
    border-radius: 15px;
}

.package-block .image .mobileImagestyle {
    height:  170px;
    width: 165px;
    display: block;
    border-radius: 15px;
}

.package-block .image .name {
    font-weight: 400;
    font-size: 20px;
    line-height: 30px;
    position: absolute;
    bottom: 15px;
    width: auto;
    display: inline-block;
    left: 0;
    padding: 5px 10px;
}

.package-block .image .tag {
    position: absolute;
    top: 25px;
    width: auto;
    display: inline-block;
    left: 0;
    padding: 5px 20px 5px 10px;
    font-weight: 500;
    font-size: 12px;
    line-height: 18px;
    text-transform: uppercase;
    // background: rgba(21, 21, 21, 0.8);
    color: #ffffff;
    // box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.35)
    background: url("/assets/images/ribbon.svg") no-repeat right center / cover;
}

.package-block .image .tag.tag1 {
    background: #661E26;
}

.package-block .price {
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
    color: #67E1F2;
    display: block;
    position: absolute;
    right: 20px;
    bottom: 25px;
}

.package-block .price span.offer {
    color: #ffffff;
    margin-left: 5px;
    text-decoration: line-through;
}
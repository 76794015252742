.my-account-blk {
    overflow: hidden;
    padding: 70px 0;
}

.my-account-blk .my-account-menu {
    width: 310px;
    margin-right: 50px;
    float: left;
    background: #F7F6F6;
    border-radius: 10px;
    font-weight: 500;
    font-size: 18px;
    line-height: 22px;
    color: #000000;
}

.my-account-blk .my-account-menu ul {
    list-style: none;
    padding: 0;
    margin: 0;
}

.my-account-blk .my-account-menu ul li {
    padding: 25px 25px 25px 82px;
    border-radius: 10px;
    position: relative;
}

.my-account-blk .my-account-menu ul li.active {
    background: #2E8B98;
    color: #ffffff;
}

.my-account-blk .my-account-menu ul li {
    color: #000000;
}

.my-account-blk .my-account-menu ul li span {
    position: absolute;
    left: 20px;
}

.my-account-blk .my-account-menu ul li span.highlight {
    display: none;
}

.my-account-blk .my-account-menu ul li.active span {
    display: none;
}

.my-account-blk .my-account-menu ul li.active span.highlight {
    display: block;
}

.my-account-blk .my-account-content {
    width: calc(100% - 360px);
    float: left;
}

.my-account-content .profile-pic-blk {
    overflow: hidden;
    padding-bottom: 55px;
    position: relative;
}

.my-account-content .profile-pic-blk .picture {
    position: relative;
    width: 136px;
    height: 136px;
    float: left;
}

.my-account-content .profile-pic-blk .picture .pic {
    overflow: hidden;
    border-radius: 136px;
    width: 136px;
    height: 136px;
    cursor: pointer;
}

.my-account-content .profile-pic-blk .picture .edit {
    background: #D9D9D9;
    border: 1px solid #FFFFFF;
    width: 45px;
    height: 45px;
    border-radius: 45px;
    display: inline-block;
    position: absolute;
    bottom: -8px;
    right: 0px;
    text-align: center;
    line-height: 45px;
}

.my-account-content .profile-pic-blk .picture-edit-cancel {
    position: relative;
    left: 40%;
    top: 43px;
    padding: 8px 12px;
    font-weight: 400;
    font-size: 14px;
    line-height: 30px;
    color: #ff0000;
    border: 1px solid #ff0000;
    border-radius: 4px;
    background: #ffffff;
    display: inline-block;
    vertical-align: middle;
    cursor: pointer;
}

.my-account-content .profile-pic-blk .picture-edit {
    position: absolute;
    right: 0;
    top: 43px;
    cursor: pointer;
}

.my-account-content .profile-pic-blk .picture-edit {
    padding: 8px 12px;
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    color: #000000;
    border: 1px solid #000000;
    border-radius: 4px;
    background: #ffffff;
    display: inline-block;
    vertical-align: middle;
}

.my-account-content .profile-pic-blk .picture-edit img {
    margin-right: 5px;
    display: inline-block;
    vertical-align: middle;
}

.my-account-content .profile-edit {
    overflow: hidden;
}

.my-account-content .profile-edit .form-fields {
    border-bottom: 1px solid rgba(150, 150, 150, 0.3);
    padding: 20px 0;
}

.my-account-content .profile-edit .form-fields label {
    font-weight: 600;
    font-size: 14px;
    line-height: 21px;
    color: #111111;
    display: block;
}

.my-account-content .profile-edit .form-fields input {
    border: 0 none;
    padding: 0;
    margin: 0;
    width: 100%;
}

.my-account-content .profile-edit .form-fields .no-data {
    font-size: 14px;
    line-height: 17px;
    color: rgba(150, 150, 150, 0.5);
}

.invisible-button {
    display: none;
}

/* Dropdown Button */
.dropbtn {
    color: white;
    padding: 16px;
    font-size: 16px;
    border: none;
}

/* The container <div> - needed to position the dropdown content */
.dropdown {
    position: relative;
    display: inline-block;
}

/* Dropdown Content (Hidden by Default) */
.dropdown-content {
    display: none;
    position: absolute;
    background-color: #f1f1f1;
    min-width: 160px;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    z-index: 1;
}

/* Links inside the dropdown */
.dropdown-content a {
    color: black;
    padding: 12px 16px;
    text-decoration: none;
    display: block;
}

/* Change color of dropdown links on hover */
.dropdown-content a:hover {
    background-color: #ddd;
}

/* Show the dropdown menu on hover */
.dropdown:hover .dropdown-content {
    display: block;
}

.error-msg {
    margin: 0;
    font-size: 10px;
    color: red;
    // visibility: hidden;
    display: none;
}

.error-msg.show {
    // visibility: visible;
    display: block;
}

/* Change the background color of the dropdown button when the dropdown content is shown */
.success-msg {
    margin: 0;
    font-size: 10px;
    color: green;
    visibility: hidden;
}

.success-msg.show {
    visibility: visible;
}

.customer-interests {
    color: #000000;
    margin-top: 25px;
}

.interests-heading {
    color: #000000;
    margin-bottom: 20px;
}

.checkboxes-div{
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 10px;
    margin-bottom: 20px;
    div{
        display: flex;
        align-items: center;
    }
}

@media screen and (max-width:578px) {
    .checkboxes-div{
        grid-template-columns: repeat(2, 1fr);
    }
    
}
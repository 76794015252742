.about-section {
    position: relative;
    margin: 30px 0;
    text-align: center;
}

.about-section ul {
    list-style: none;
    margin: 0;
    padding: 0;
}

.about-section ul li {
    display: inline-block;
    vertical-align: middle;
    margin:0 65px 30px 0;
}

.about-section ul li:last-child {
    margin-right: 0;
}

.about-section ul li span {
    display: inline-block;
    vertical-align: middle;
    margin-right: 10px;
}

.about-section ul li span.text {
    text-align: left;
    font-weight: 600;
    font-size: 14px;
    line-height: 21px;
}

@media screen and (max-width: 768px) {
    .about-section ul li span.text{
        margin-top: 20px;
    }
}
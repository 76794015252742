.footer-section {
    // background: url(../images/footer-bg.png) no-repeat center center / cover;
    margin: 40px 0 0 0;
    padding: 70px 0 30px;
}

.footer-section .left {
    width: 50%;
    float: left;
}

.footer-section .footer-logo {
    overflow: hidden;
    margin-bottom: 20px;
}

.footer-section .footer-logo .logo-text {
    font-weight: 400;
    font-size: 30px;
    line-height: 45px;
    color: #46A0B3;
    margin-right: 50px;
}

.footer-section .footer-logo ul,
.footer-section .footer-menu ul {
    list-style: none;
    margin: 0;
    padding: 0;
}

.footer-section .footer-logo ul li {
    display: inline-block;
    vertical-align: middle;
    margin-right: 20px;
}

.footer-section .footer-menu {
    margin-top: 40px;
}

.footer-section .footer-menu ul li {
    display: inline-block;
    vertical-align: middle;
    width: 32%;
    margin-bottom: 15px;
}

.footer-section .footer-menu ul li a {
    font-weight: 400;
    font-size: 14px;
    line-height: 23px;
    color: #FFFFFF;
}

.footer-section .footer-newsletter {
    margin-top: 30px;
}

.footer-section .footer-newsletter .heading,
.footer-section .heading {
    font-weight: 400;
    font-size: 16px;
    line-height: 23px;
    margin-bottom: 10px;
}

.footer-section .footer-newsletter .input input {
    background: rgba(255, 255, 255, 0.1);
    border-radius: 5px;
    font-weight: 500;
    font-size: 12px;
    line-height: 23px;
    color: #ffffff;
    padding: 8px 15px;
    border: rgba(255, 255, 255, 0.1);
    margin-right: 5px;
    min-width: 250px;
}

.footer-section .footer-newsletter .input input.green-btn {
    background: #2E8B98;
    border-radius: 6px;
    font-weight: 500;
    font-size: 12px;
    line-height: 24px;
    text-transform: uppercase;
    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    padding: 8px 25px;
    display: inline-block;
    color: #ffffff;
    min-width: inherit;
}

.footer-section .right {
    width: 25%;
    float: right;
}

.footer-section .form {
    overflow: hidden;
}

.footer-section .form .form-fields {
    margin-bottom: 15px;
}

.footer-section .form input,
.footer-section .form textarea {
    background: rgba(255, 255, 255, 0.1);
    border-radius: 5px;
    font-weight: 500;
    font-size: 12px;
    line-height: 23px;
    color: #ffffff;
    padding: 8px 15px;
    border: rgba(255, 255, 255, 0.1);
    margin-right: 5px;
    resize: none;
    width: calc(100% - 30px);
}

.footer-section .form input.green-btn {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background: #2E8B98;
    text-transform: uppercase;
    width: 100%;
}

.footer-copyright {
    margin-top: 20px;
    color: rgba(255, 255, 255, 0.5);
    font-weight: 500;
    font-size: 12px;
    line-height: 23px;
}

.footer-copyright a {
    color: rgba(255, 255, 255, 0.5);
}

.floating-icons {
    position: fixed;
    top: 80%;
    right: 12px;
    transform: translate(0, -50%);
    z-index: 1;
}

.floating-icons ul {
    list-style: none;
    margin: 0;
    padding: 0;
}

.floating-icons ul li {
    margin-bottom: 12px;
    font-size: 1em;
    width: 45px;
}

.floating-icons ul li img {
    position: relative;
    cursor: pointer;
}

.floating-icons ul li:last-child {
    margin-bottom: 0;
}

.floating-icons ul li span {
    display: none;
    position: absolute;
    right: 0;
    width: 110px;
    color: #fff;
    top: 0px;
    background: #314045;
    padding: 10px 60px 10px 20px;
    border-radius: 100px;
    line-height: 27px;
}

.floating-icons ul li:hover span {
    display: inline-block;
}

.w24 {
    width: 24px;
}
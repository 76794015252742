.testimonial-section {
    width: 100%;
    overflow: hidden;
    margin: 20px 0 40px;
}

.testimonial-section .heading {
    font-weight: 500;
    font-size: 25px;
    line-height: 40px;
    text-align: center;
    margin-bottom: 10px;
}

.testimonial-section .testimonial-blk {
    background: rgba(108, 108, 108, 0.2);
    border: 1px solid rgba(108, 108, 108, 0.2);
    border-radius: 10px;
    max-width: 800px;
    margin: 0 auto;
    padding: 30px;
    text-align: center;
    overflow: hidden;
}
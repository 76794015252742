span {
    display: inline-block;
    text-align: left;
}

span.name {
    font-weight: 400;
    font-size: 20px;
    line-height: 30px;
    position: absolute;
    bottom: 15px;
    width: auto;
    display: inline-block;
    left: 0;
    padding: 5px 10px;
}

span.image {
    position: relative;
}

span.image .tag {
    position: absolute;
    top: 20px;
    width: auto;
    display: inline-block;
    left: 0;
    padding: 5px 20px 5px 5px;
    font-weight: 500;
    font-size: 12px;
    line-height: 18px;
    // text-transform: uppercase;
    // background: rgba(21, 21, 21, 0.8);
    color: #ffffff;
    // box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.35)
    background: url("/assets/images/ribbon.svg") no-repeat right center / cover;
}

span.head {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    margin-top: 15px;
    display: block;
}

span.days {
    font-weight: 600;
    font-size: 13px;
    line-height: 20px;
    margin-top: 5px;
    display: block;
}

span.price {
    font-weight: 600;
    font-size: 16px;
    line-height: 18px;
    color: #67E1F2;
    display: block;
    margin-top: 5px;
}

span.price span.offer {
    color: #ffffff;
    // margin-left: 5px;
    text-decoration: line-through;
}

.link-btn {
    cursor: pointer;
    color: #2E8B98;
}

.link {
    cursor: pointer;
}

.points-earned {
    font-weight: 400;
    font-size: 1rem;
    line-height: 18px;
    color: white;
    margin: 6px;
    display: inline-block;
}

.points-earned span {
    font-weight: 800;
    color: #2E8B98;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}

.points-earned-detail {
    font-weight: 600;
    font-size: 1rem;
    line-height: 18px;
    color: #2E8B98;
    margin: 6px;
    display: inline-block;
}

.sitemap-css {
    box-sizing: border-box;
  }
  
  .sitemap-sub-division {
    float: left;
    width: 50%;
    padding: 10px;
    padding-bottom: 20px;
  }

  .inner-division {
    float: left;
    width: 30%;
    padding: 3px;
  }
  
  .sitemap-division:after {
    // content: "";
    display: table;
    clear: both;
    padding: 5px;
  }
  
  @media screen and (max-width: 600px) {
    .sitemap-sub-division {
      width: 100%;
    }
    .inner-division {
      width: 100%;
    }
  }

.subhead {    
    font-size: 22px;
    color: #ffffff;
    font-weight: 700;
    padding: 5px;
    margin-bottom: 10px;
}

.sitemap-links {
    color: #ffffff;
}

.sitemap-links:hover{
    color: #1AB2D2;
}

.division-head{
    font-size: 18px;
    color: #1AB2D2;
    font-weight: 400;
    padding: 5px;
    margin-bottom: 10px;
}

.section-division {
    max-width: 1140px;
    overflow: hidden;
    padding: 0 10px;
    margin: 30px 10px;
}

.line-item {
  padding-left: 15px;
  padding: 3px;
}

.sitemap-section {
  max-width: 1140px;
    overflow: hidden;
    padding: 0 70px;
    margin: 0 auto;
}
.faq-blk,
.about-us-blk {
    max-width: 735px;
    margin: 0 auto;
}

.about-us-blk {
    margin-bottom: 40px;
}

.about-us-blk p {
    margin: 0 0 20px 0;
}

.text-center {
    text-align: center !important;
}

.media-coverage {
    overflow: hidden;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    color: #111111;
    margin: 30px 0 0;
}

.media-coverage .head {
    overflow: hidden;
    font-weight: 600;
    font-size: 30px;
    line-height: 36px;
    margin-bottom: 30px;
    color: #111111;
}

.media-coverage-blk {
    overflow: hidden;
    margin: 50px 0 20px;
}

.media-coverage-blk .media-details {
    width: 50%;
    float: left;
    margin-bottom: 30px;
}

.media-coverage-blk .media-details .image {
    border-radius: 10px;
    overflow: hidden;
    width: 195px;
    margin-right: 20px;
    float: left;
    position: relative;
    min-height: 100px;
}

.media-coverage-blk .media-details .image .media-logo {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 75%;
    text-align: center;
}

.media-coverage-blk .media-details .image .media-logo img{
    margin: 0 auto;
    display: inline-block;
}

.media-coverage-blk .media-details .desc {
    width: calc(100% - 235px);
    float: left;
}

.media-coverage-blk .media-details .desc .head {
    font-weight: 700;
    font-size: 16px;
    line-height: 19px;
    margin-bottom: 15px;
}

.media-coverage-blk .media-details .desc .content {
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    margin-bottom: 15px;
}

.media-coverage-blk .media-details .desc .more {
    font-weight: 700;
    font-size: 16px;
    line-height: 19px;
}

.media-coverage-blk .media-details .desc .more a {
    color: #38B1C2;
}

.media-coverage-blk .splitter {
    margin-bottom: 35px;
    clear: both;
    overflow: hidden;
}

.media-coverage .view-all {
    font-weight: 700;
    font-size: 20px;
    line-height: 24px;
    text-align: center;
    color: #38B1C2;
    margin-top: 40px;
}

.media-coverage .view-all a {
    color: #38B1C2;
}

.gray-bg {
    background: rgba(255, 255, 255, 0.9);
    padding: 50px 0;
}

.partners {
    max-width: 735px;
    margin: 0px auto;
    color: #111111;
}

.partners .head {
    overflow: hidden;
    font-weight: 600;
    font-size: 30px;
    line-height: 36px;
    margin-bottom: 30px;
    color: #111111;
    text-align: center;
}

.partners-blk {
    overflow: hidden;
    text-align: center;
}

.partners-blk .partners-content {
    width: calc(25% - 20px);
    float: left;
    margin: 0 10px;
}

.partners-blk .partners-content.explore .image {
    padding: 0;
    border-radius: 10px;
    overflow: hidden;
}

.partners-blk .partners-content.explore .image img {
    width: 100%;
    display: block;
    height: auto;
}

.partners-blk .partners-content .image {
    background: #FFFFFF;
    box-shadow: 0px 4px 14px rgba(89, 89, 89, 0.25);
    border-radius: 10px;
    text-align: center;
    padding: 30px;
    margin-bottom: 10px;
}

.partners-blk .partners-content .image img {
    height: 60px;
}

.mt-60 {
    margin-top: 60px;
}

.black-bg {
    padding: 50px 0;
    background: #292929;

    .partners .head {
        color: #FFFFFF;
    }

    .partners-blk .partners-content {
        width: calc(33% - 20px);
    }

    .content {
        text-align: center;
        font-size: 20px;
        font-weight: 700;
        color: #1AB2D2;
    }
}

.inner-heading.about-us {
    margin: 60px auto 40px;
    font-style: italic;
    width: 60%;
    font-family: serif;
}

.p0 {
    padding: 0;
}

.mobile-scroll {
    width: 100%;
    overflow: auto;
}

.gray-bg.about-us {
    padding: 25px 0;
}

.our-corporation-desktop {
    width: 2550px;
}

.our-corporation-mobile {
    width: auto
}

// .image-style {
//     height: 120px;
// }
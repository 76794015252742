.places-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background: rgba(40, 40, 40, 0.5);
  z-index: 3;
}

.places-listing-popup {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: #262626;
  padding: 15px;
  color: #000;
  width: 75%;
  height: 85%;
  border-radius: 10px;
}

.places-overlay-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  // height: 4%;
  margin-bottom: 20px;
  padding: 0px 10px;
}

.places-overlay-header-text {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 26%;
}

.places-overlay-header-search-close {
  width: 25%;
  display: flex;
  flex-direction: row;
  justify-content: end;
  align-content: center;
  gap: 5%;
}

.places-hotel-search-txt {
  padding: 18px;
  width: 90%;
  border-radius: 100px;
  border: none;
  background: #1f1f1f;
  color: #ffffff;
}

.places-close-btn {
  align-items: center;
  text-align: center;
  background: #1f1f1f;
  color: #fff;
  border: none;
  font-weight: 800;
  font-size: 20px;
  border-radius: 50px;
}

.places-overlay-header-txt {
  font-size: 140%;
  font-weight: 700;
  color: #ffffff;
  width: 100%;
}

.overlay-text {
  color: white;
  margin-top: 5px;
  font-weight: 600;
  font-size: 16px;
}

.overlay-close-btn {
  display: flex;
  justify-content: center;
  margin-top: 10px;
}

.places-overlay-header-city {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  color: #000;
}

.places-overlay-heading-city {
  color: #000;
}

.places-overlay-content {
  height: 90%;
  display: flex;
  flex-direction: row;
  padding: 0.5%;
  gap: 1%;
}

.poi-overlay-content {
  height: 83%;
  display: flex;
  flex-direction: row;
  padding: 0.5%;
  gap: 2%;
}

.places-filter-div {
  background: #1f1f1f;
  position: relative;
  // width: 20%;
  width: 300px;
  border-radius: 10px;
  overflow-y: auto;
}

.places-results-div {
  background-color: #262626;
  width: 80%;
  border-radius: 10px;
  height: 100%;
  display: flex;
  gap: 1%;
  flex-direction: column;
  justify-content: space-between;
  flex-grow: 1;
}

.places-results-div-content {
  background-color: #262626;
  border-radius: 10px;
  overflow-y: auto;
  height: 100%;
}

.places-listing-img-div {
  width: 30%;
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.place-of-interest-img {
  width: 90%;
  max-height: 170px;
  border-radius: 20px;
  padding: 15px;
}

.places-listing-div {
  width: 80%;
  padding-right: 20px;
}

.places-listing-img {
  height: 100%;
  width: 100%;
  border-radius: 10px;
}

.places-listing-section {
  display: flex;
  flex-direction: row;
  align-items: start;
  margin-bottom: 10px;
  border-radius: 10px;
  padding: 10px;
}

.places-not-added {
  background: #1f1f1f;
}

.places-selected {
  background: #116476;
}

.place-of-interest-time-rating {
  margin-top: 15px;
  margin-bottom: 10px;
  display: flex;
  justify-content: space-between;
  padding-right: 10px;
}

.places-listing-header-div {
  display: flex;
  flex-direction: row;
  align-items: baseline;
  justify-content: space-between;
  width: 100%;
}

.places-listing-header-txt {
  font-weight: 600;
  color: #fff;
}

.places-listing-header-btn {
  display: inline;
  border-radius: 5px;
  padding: 10px 25px;
  cursor: pointer;
  background: #2e8b98;
  color: #fff;
  border: none;
}
.place-of-interest-listing-details-text{
  width: 100%;
  text-align: justify;
}

.places-listing-header-btn-added {
  display: inline;
  border-radius: 5px;
  padding: 10px 25px;
  background: none;
  color: #fff;
  border: none;
}

.places-listing-header-remove-btn {
  display: inline;
  border-radius: 5px;
  padding: 10px 25px;
  cursor: pointer;
  background: red;
  color: #fff;
  border: none;
}

.places-listing-details-div {
  color: #fff;
}

.no-places-msg {
  color: #fff;
  width: 100%;
  height: 40%;
  text-align: center;
  font-weight: 500;
}

@media screen and (max-width: 767px) {
  .places-results-div {
    width: 100%;
  }
  .overlay-close-btn{
    display: none;
  }
  .poi-overlay-content {
    flex-direction: column;
  }
  .places-filter-div {
    flex-shrink: 0;
    width: 100%;
  }
  .places-listing-popup {
    overflow-y: auto;
}

.places-results-div {
  width: 100%;
}
.places-listing-section{
  flex-direction: column;
  padding: 15px;
}
.places-listing-div{
  width: calc(100% - 20px);
  padding-right: 0px;
}
.place-of-interest-listing-details-text{
  width: 100%;
  text-align: justify;
}
.place-of-interest-img{
  padding: 0px;
}
.places-overlay-header{
  flex-direction: column;
  gap:10px;
}
}

.login-section {
  background: url("../../assets/images/login-bg.png") no-repeat center center /
    cover;
  padding: 120px 0 90px;
}

.login-section .heading {
  font-weight: 600;
  font-size: 40px;
  line-height: 45px;
  text-align: center;
  color: #ffffff;
  margin-bottom: 30px;
}

.login-container {
  max-width: 400px;
  margin: 0 auto;
  padding: 30px 90px 60px;
  background: #222222;
  border-radius: 10px;
}

.login-container .login-text {
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  text-align: center;
  letter-spacing: 0.03em;
  color: #ffffff;
  margin-bottom: 20px;
}

.login-container .login-text a {
  color: #67e1f2;
}

.login-container .head {
  font-weight: 600;
  font-size: 25px;
  line-height: 38px;
  text-align: center;
  margin-bottom: 30px;
}

.login-container .form {
  overflow: hidden;
}

.login-container .form-fields {
  width: auto;
  background: #181818;
  border-radius: 4px;
  margin-bottom: 15px;
  padding: 8px 16px;
  position: relative;
  overflow: hidden;
  border: 1px solid #181818;
}

.login-container .form-fields.error {
  border: 1px solid red;
}

.login-container .form-fields.no-bg {
  background: none;
  padding: 0;
  border: none;
}

.login-container .form-fields label {
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  display: block;
  margin-bottom: 10px;
}

.login-container .form-fields input {
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  background: #181818;
  border: 1px solid #181818;
  width: calc(100% - 50px);
  padding: 0 50px 0 0;
  outline: none;
  color: #ffffff;
}

.login-container .form-fields input:hover,
.login-container .form-fields input:focus {
  outline: none;
}

.login-container .form-fields .icon {
  position: absolute;
  bottom: 9px;
  right: 20px;
  width: 20px;
}

.login-container .form-fields input.green-btn-input {
  background: #2e8b98;
  border-radius: 4px;
  border: 1px solid #2e8b98;
  width: 100%;
  padding: 8px 16px;
  color: #ffffff;
  cursor: pointer;
  margin-top: 20px;
}

.login-container .form-fields input.green-btn-input.login {
  width: calc(50% - 10px);
}

.login-container .form-fields input.gray-btn {
  background: #282828;
  border-radius: 4px;
  border: 1px solid #282828;
  width: 100%;
  padding: 8px 16px;
  color: #ffffff;
  cursor: pointer;
  margin-top: 20px;
}

.login-container .form-fields .left,
.login-container .form-fields .right {
  float: left;
  width: 50%;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  letter-spacing: 0.03em;
  color: #ffffff;
}

.login-container .form-fields .right {
  text-align: right;
}

.login-container .form-fields .right-align {
  text-align: right;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  letter-spacing: 0.03em;
  cursor: pointer;
}

.login-container .form-fields .left a,
.login-container .form-fields .right-align p,
.login-container .form-fields .right a {
  color: #67e1f2;
}

.login-container .form-fields input.checkbox {
  width: auto;
  display: inline-block;
  vertical-align: middle;
  margin: 0;
  padding: 0;
}

.login-container .form-fields.w100 .left {
  width: 100%;
  float: none;
}

.login-container .form-fields input.gray-btn.mr-5 {
  margin-right: 10px;
  width: calc(100% - 10px);
}

.login-container .form-fields input.green-btn.ml-5 {
  margin-left: 10px;
  width: calc(100% - 10px);
}

@media screen and (max-width:767px) {
  .login-container .form-fields input.green-btn-input.login{
    width: 100%;
  }
}
.inner-page {
  background: #151515 url("../../assets/images/listing-bg.png") no-repeat top
    center / 100%;
  padding-top: 80px;
  position: relative;
}

.bottom-inner-image {
  position: absolute;
  bottom: 0;
  background: url("../../assets/images/listing-bottom-image.png") no-repeat top
    center / cover;
  width: 100%;
  min-height: 355px;
}

.breadcrum {
  padding: 20px 0 15px;
}

.breadcrum ul {
  margin: 0;
  padding: 0;
}

.breadcrum ul li {
  list-style: none;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: #ffffff;
  display: inline-block;
  vertical-align: middle;
  margin-right: 5px;
}

.breadcrum ul li a {
  color: #1ab2d2;
  font-weight: 400;
  font-size: 14px;
}

.inner-heading {
  margin-bottom: 10px;
  min-height: 37px;
}

.inner-sub-head {
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: #ffffff;
  margin-bottom: 15px;
  text-transform: capitalize;
}

.inner-heading .head {
  float: left;
  font-weight: 500;
  font-size: 25px;
  line-height: 30px;
  color: #ffffff;
}

.inner-heading .filter-section {
  float: right;
}

.filter-section .sort-by,
.filter-section .sort-results {
  float: left;
}

.filter-section .sort-by {
  // margin-right: 20px;
}

.filter-section .sort-results {
  margin: 7px 0 7px 0;
  border-left: 1px solid #fff;
  padding-left: 20px;
  font-size: 14px;
  line-height: 21px;
  font-weight: 400;
}

.filter-section .sort-by ul {
  margin: 0;
  padding: 0;
}

.filter-section .sort-by ul li {
  list-style: none;
  background: #ffffff;
  border: 1px solid rgba(255, 255, 255, 0.3);
  box-shadow: 0px 3px 14px rgba(0, 0, 0, 0.12);
  border-radius: 80px;
  font-weight: 600;
  font-size: 12px;
  line-height: 18px;
  color: #111111;
  position: relative;
  padding: 8px 40px 8px 15px;
}

.filter-section .sort-by ul li span {
  font-weight: 400;
  /*margin-right: 10px;*/
}

.filter-section .sort-by ul li span.arrow {
  width: 10px;
  height: 7px;
  margin: 0;
  position: absolute;
  right: 15px;
  cursor: pointer;
}

.filter-section .sort-by ul li ul {
  position: absolute;
  display: none;
  top: 35px;
  left: -1px;
  background: #ffffff;
  border: 1px solid rgba(255, 255, 255, 0.3);
  -webkit-border-bottom-right-radius: 10px;
  -webkit-border-bottom-left-radius: 10px;
  -moz-border-radius-bottomright: 10px;
  -moz-border-radius-bottomleft: 10px;
  border-bottom-right-radius: 10px;
  border-bottom-left-radius: 10px;
  padding: 0 0 5px 0;
  width: 100%;
  z-index: 2;
}

.filter-section .sort-by ul li.active {
  -webkit-border-radius: 0px;
  -webkit-border-top-left-radius: 10px;
  -webkit-border-top-right-radius: 10px;
  -moz-border-radius: 0px;
  -moz-border-radius-topleft: 10px;
  -moz-border-radius-topright: 10px;
  border-radius: 0px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

.filter-section .sort-by ul li.active span.arrow {
  transform: rotate(180deg);
  top: 19px;
}

.filter-section .sort-by ul li.active ul {
  display: block;
}

.filter-section .sort-by ul li.active ul li {
  border-radius: 0;
  box-shadow: none;
  cursor: pointer;
  padding: 5px 15px;
  font-weight: 400;
}

.filter-results {
  overflow: hidden;
  position: relative;
  z-index: 1;
}

.filter-by {
  background: rgba(34, 34, 34, 0.6);
  border-radius: 10px;
  padding: 30px 0;
  width: 270px;
  float: left;
  margin-right: 30px;
}

.filter-by .head-container {
  padding: 0px 25px 0 0px;
}

.filter-by .head-container .head {
  font-weight: 400;
  font-size: 20px;
  line-height: 30px;
  color: #ffffff;
  padding: 0 25px;
  margin-bottom: 15px;
  position: relative;
}

.filter-by .acc-blk {
  border-bottom: 1px solid rgba(77, 77, 77, 0.5);
  padding: 15px 25px;
}

.filter-by .acc-blk .acc-head {
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: #ffffff;
  position: relative;
}

.filter-by .acc-blk .acc-head span.arrow,
.filter-by .head-container .head span.arrow {
  position: absolute;
  right: 0px;
}

.filter-by .acc-blk .acc-head span.arrow.active,
.filter-by .head-container .head span.arrow.active {
  transform: rotate(180deg);
  top: 2px;
}

.filter-by .acc-blk .acc-content {
  overflow: hidden;
  margin-top: 20px;
}

.filter-by .acc-blk .acc-content ul {
  margin: 0;
  padding: 0;
}

.filter-by .acc-blk .acc-content ul li {
  list-style: none;
  font-weight: 400;
  font-size: 12px;
  line-height: 21px;
  margin-bottom: 15px;
}

.filter-by .acc-blk .acc-content ul li a {
  display: block;
  vertical-align: middle;
  color: #ffffff;
}

.filter-by .acc-blk .acc-content ul li span.checkbox,
.filter-by .acc-blk .acc-content ul li span.expand {
  background: url("../../assets/images/checkbox.png") no-repeat center center /
    cover;
  width: 20px;
  height: 20px;
  display: inline-block;
  vertical-align: middle;
  margin-right: 10px;
  cursor: pointer;
}

.filter-by .acc-blk .acc-content ul li span.expand {
  background: url("../../assets/images/plus.png") no-repeat center center /
    cover;
}

.filter-by .acc-blk .acc-content ul li span.checkbox.active {
  background: url("../../assets/images/checkbox-active.png") no-repeat center
    center / cover;
}

.filter-by .acc-blk .acc-content ul li span.expand.active {
  background: url("../../assets/images/minus.png") no-repeat center center /
    cover;
}

.filter-by .apply-btn {
  background: #2e8b98;
  border-radius: 6px;
  padding: 8px 10px;
  margin: 25px 25px 0;
  text-align: center;
  cursor: pointer;
}

.filter-by .acc-blk .acc-content ul li ul {
  margin-left: 35px;
}

.filter-by .acc-blk .acc-content ul li ul li {
  margin: 10px 0 0 0;
  position: relative;
}

.filter-by .acc-blk .acc-content ul li ul li span.count {
  position: absolute;
  right: 0px;
}

.filter-results-data {
  float: left;
  width: calc(100% - 300px);
  overflow: auto;
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 18px;
  // hidden;
}

.filter-result-blk {
  background: #222222;
  border-radius: 10px;
  padding: 10px 25px 5px 10px;
  overflow: hidden;
  // margin-bottom: 15px;
  position: relative;
}

.filter-result-blk .image {
  border-radius: 10px;
  overflow: hidden;
  float: left;
  width: 300px;
  margin-right: 20px;
  position: relative;
  height: 245px;
}

.filter-result-blk .image img {
  width: 100%;
  min-height: 245px;
  border-radius: 15px;
}

.filter-result-blk .image span {
  // background: rgba(21, 21, 21, 0.8);
  font-weight: 600;
  font-size: 12px;
  line-height: 18px;
  // text-transform: uppercase;
  color: #ffffff;
  position: absolute;
  padding: 5px 20px 5px 5px;
  top: 25px;
  left: 0;
  background: url("/assets/images/ribbon.svg") no-repeat right center / cover;
}

.filter-result-blk .content {
  float: left;
  width: calc(100% - 320px);
}

.filter-result-blk .content .sub-title {
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  margin: 20px 0 10px;
  text-transform: capitalize;
}

.filter-result-blk .content .title {
  font-weight: 400;
  font-size: 20px;
  line-height: 25px;
  margin-bottom: 10px;
}

.filter-result-blk .content .days {
  font-weight: 600;
  font-size: 13px;
  line-height: 20px;
  margin-bottom: 15px;
}

.filter-result-blk .content .description {
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  margin-bottom: 20px;
}

.filter-result-blk .content .description span {
  font-weight: 600;
}

.filter-result-blk .content .description span.normal {
  display: contents;
  font-weight: normal;
}

.filter-result-blk .content .offer-blk {
  overflow: hidden;
  position: absolute;
  display: flex;
  justify-content: space-between;
  align-items: center;
  bottom: 14px;
  width: calc(100% - 355px);
  .booknow-btn {
    background: #2e8b98;
    // box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.15);
    border-radius: 5px;
    padding: 10px 25px;
    border: none;
    color: #ffffff;
    cursor: pointer;
  }
}

.filter-result-blk .content .offer-blk .left {
  float: left;
}

.filter-result-blk .content .offer-blk .left .offer-ends {
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  color: #f14e4e;
  margin-bottom: 5px;
}

.filter-result-blk .content .offer-blk .left .price {
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  color: #67e1f2;
}

.filter-result-blk .content .offer-blk .left .price span {
  text-decoration: line-through;
  margin-right: 10px;
  color: #ffffff;
}

.filter-result-blk .content .offer-blk .right {
  float: right;
}

.filter-result-blk .content .offer-blk .right .book-btn {
  background: #2e8b98;
  // box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.15);
  border-radius: 5px;
  padding: 10px 25px;
  border: none;
  color: #ffffff;
  cursor: pointer;
}

.filter-result-blk .content .offer-blk .right .book-btn a {
  color: #ffffff;
}

.info-txt {
  text-align: center;
  margin: 50px 0;
  font-weight: 700;
}

.info-txt.view-more {
  cursor: pointer;
  color: #26e4ff;
}

.cursor-pointer {
  cursor: "pointer";
}

@media screen and (max-width:425px) {
  .inner-heading .head {
    font-size: 15px;
  }
  .offer-blk{
    flex-direction: column;
    gap: 5px !important;
    align-items: flex-start !important;
    justify-content: flex-start !important;
  }
}
.inner-heading .head.text-center {
    text-align: center;
    float: none;
}

.privacy-blk {
    max-width: 800px;
    margin: 0 auto;
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    color: #FFFFFF;
}

.privacy-blk p.bold {
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
}

.privacy-blk p {
    margin-bottom: 30px;
}

.privacy-blk ul {
    margin: 0;
    padding-left: 15px;
}

.privacy-blk ul li {
    margin-bottom: 20px;
}
.pagination-holder {
    text-align: center;
    margin: 50px 0;
    font-weight: 700;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    font-size: 14px;
    border-radius: 10px;
    padding: 8px 10px;
}
.path{
    background: #222222;
}
.non-path{
    background: #f8f8f8;
}
.non-path-text{
    color: black;
}
.path-text{
    color: white;
}

@media screen and (min-width: 100px) and (max-width: 767px) {
    .pagination-holder {
        flex-direction: column;
        align-items: normal;
    }

    .pagination-right-container {
        padding-top: 5px;
        justify-content: center !important;
        padding-bottom: 15px;
    }
}

.pagination-left-container {
    flex-grow: 1;
    display: flex;
    text-align: center;
    font-weight: 700;
    align-items: center;
    justify-content: space-between;
}

.pagination-left-container-non {
    flex-grow: 1;
    display: flex;
    text-align: center;
    font-weight: 700;
    align-items: center;
    justify-content: space-between;
}

.pagination-right-container {
    flex-grow: 3;
    display: flex;
    text-align: center;
    align-items: center;
    justify-content: end;
    font-size: 12px;
    font-weight: 500;
}

.pagination-holder button {
    border: none;
    cursor: pointer;
    width: 30px;
    height: 30px;
    border-radius: 4px;
}

.pagination-holder .inactive {
    color: white;
    margin: 10px 0px;
    padding: 8px 5px;
    cursor: pointer;
}
.non-inactive{
    color: black;
    margin: 10px 0px;
    padding: 8px 5px;
    cursor: pointer;
}

.pagination-holder .active {
    background-color: #2E8B98;
    color: white;
    padding: 8px 16px;
    border-radius: 50px;
}

.pagination-next {
    background: url('../../assets/images/next-icon.png') no-repeat center center / cover;
}

.pagination-prev {
    background: url('../../assets/images/previous-icon.png') no-repeat center center / cover;
}

.pagination-prev-disabled {
    cursor: not-allowed !important;
    background: url('../../assets/images/previous-disabled-icon.png') no-repeat center center / cover;
}

.pagination-next-disabled {
    cursor: not-allowed !important;
    background: url('../../assets/images/next-disabled-icon.png') no-repeat center center / cover;
}
.confirm-blk {
  padding: 20px 0;
  color: #111111;
}

.confirm-blk .head {
  font-weight: 600;
  font-size: 30px;
  line-height: 45px;
  margin-bottom: 20px;
  color: #111111;
}

.confirm-layout {
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  overflow: hidden;
}

.confirm-layout .left {
  width: 700px;
  float: left;
  overflow: hidden;
}

.confirm-layout .left .room-details {
  overflow: hidden;
}

.confirm-layout .left .splitter {
  border-bottom: 1px solid #969696;
  margin: 30px 0;
}

.confirm-layout .left .room-image {
  width: 208px;
  float: left;
}

.confirm-layout .left .room-image .img {
  margin-bottom: 20px;
  max-height: 200px;
}

.confirm-layout .left .room-image .img img {
  object-fit: cover;
  height: 200px;
  width: 100%;
  background: rgba(0, 0, 0, 0.4);
}

.confirm-layout .left .room-image ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

.confirm-layout .left .room-image ul li {
  display: inline-block;
  vertical-align: middle;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  margin-left: 30px;
}

.confirm-layout .left .room-image ul li:first-child {
  margin-left: 0;
}

.confirm-layout .left .room-image ul li a {
  color: #111111;
}

.confirm-layout .left .room-image ul li img {
  display: inline-block;
  vertical-align: middle;
  margin-right: 10px;
}

.confirm-layout .left .room-content {
  width: calc(100% - 228px);
  float: right;
}

.confirm-layout .left .room-content p {
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
  margin-top: 30px;
}

.confirm-layout .left .room-content .sub-head {
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  margin-bottom: 8px;
  color: #111111;
}

.confirm-layout .left .room-content .head {
  font-weight: 600;
  font-size: 22px;
  line-height: 33px;
  margin-bottom: 20px;
  color: #111111;
}

.confirm-layout .left .room-content .room-date {
  list-style: none;
  margin: 0;
  padding: 0;
  margin-top: 25px;
}

.confirm-layout .left .room-content .room-date li {
  display: inline-block;
  vertical-align: middle;
  border-left: 1px solid #bcbcbc;
  padding: 0 15px;
  font-weight: 500;
  font-size: 15px;
  line-height: 19px;
}

.confirm-layout .left .room-content .room-date li span {
  display: block;
  position: relative;
}

.confirm-layout .left .room-content .room-date li span.head {
  font-weight: 600;
  font-size: 14px;
  line-height: 21px;
  margin-bottom: 10px;
}

.confirm-layout .left .room-content .room-date li span.detail {
  padding-right: 30px;
}

.confirm-layout .left .room-content .room-date li span.detail img {
  position: absolute;
  right: 0;
  top: -3px;
  cursor: pointer;
}

.confirm-layout .left .room-content .room-date li:first-child {
  border: 0 none;
  padding-left: 0;
}

.confirm-layout .right {
  width: 380px;
  float: right;
  background: #f7f6f6;
  border-radius: 10px;
  padding: 20px;
}

.confirm-layout .right.mt20 {
  margin-top: 20px;
}

.confirm-layout .left .inner-head {
  font-weight: 600;
  font-size: 22px;
  line-height: 33px;
  margin-bottom: 30px;
  color: #111111;
}

.room-details .room-form-fields {
  overflow: hidden;
}
.room-details {
  margin-top: 20px;
}

.room-details .room-form-fields .form {
  width: 33.33%;
  float: left;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  position: relative;
}

.room-details .room-form-fields .form.w100 {
  width: auto;
  float: none;
}

.room-details .room-form-fields .form label {
  font-weight: 600;
  font-size: 14px;
  line-height: 21px;
  display: block;
  position: relative;
  margin-bottom: 10px;
}

.room-details .room-form-fields .form label span.checkbox {
  position: absolute;
  right: 20px;
}

.room-details .room-form-fields .form label span.checkbox input {
  display: inline-block;
  vertical-align: middle;
  width: auto;
}

.room-details .room-form-fields .form input {
  background: #ffffff;
  border: 0 none;
  outline: 0 none;
  width: calc(100% - 60px);
  margin: 0;
  padding: 0;
  border-bottom: 1px solid #ccc;
}

.room-details .room-form-fields .form input.w50 {
  width: 50%;
}

.room-details .room-form-fields .form input.submit {
  background: #ffffff;
  border: 1px solid rgba(17, 17, 17, 0.6);
  border-radius: 4px;
  padding: 6px 12px;
  display: inline-block;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  color: rgba(17, 17, 17, 0.6);
  width: auto;
  // margin-top: 20px;
  cursor: pointer;
}

.room-details .room-form-fields .form img {
  position: absolute;
  bottom: 3px;
  right: 20px;
  cursor: pointer;
}

.room-details .room-form-fields .form p.note {
  font-weight: 400;
  font-size: 8px;
  line-height: 12px;
  color: rgba(17, 17, 17, 0.6);
  margin-top: 5px;
}

.room-details p.note {
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
}

.room-details p.note span {
  font-weight: 600;
}

.confirm-layout .right .head {
  font-weight: 600;
  font-size: 25px;
  line-height: 38px;
  margin-bottom: 0px;
}

.confirm-layout .right .total-amount {
  overflow: hidden;
  padding: 15px 0;
  width: auto;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
}

.confirm-layout .right .total-amount .left {
  float: left;
  background: none;
  width: auto;
}

.confirm-layout .right .total-amount .left.w70 {
  width: 70%;
}

.confirm-layout .right .total-amount .right {
  float: right;
  background: none;
  width: auto;
  padding: 0;
  border-radius: 0;
}

.confirm-layout .right .total-amount.border-bottom {
  border-bottom: 1px solid #969696;
}

.confirm-layout .right .total-amount .left.bold,
.confirm-layout .right .total-amount .right.bold {
  font-weight: 700;
}

.confirm-layout .right .total-amount .left.extra-bold,
.confirm-layout .right .total-amount .right.extra-bold {
  font-weight: 700;
  font-size: 18px;
  line-height: 27px;
}

.confirm-layout .right .form-fields .normal-btn {
  background: #ffffff;
  border: 1px solid rgba(0, 0, 0, 0.5);
  border-radius: 10px;
  padding: 13px 20px;
  text-align: center;
  color: #111111;
  cursor: pointer;
  margin-top: 15px;
}

.confirm-layout .right .form-fields .green-btn {
  background: #2e8b98;
  border: 1px solid #2e8b98;
  border-radius: 10px;
  padding: 13px 20px;
  text-align: center;
  color: #ffffff;
  cursor: pointer;
  width: calc(100% - 40px);
  margin-top: 15px;
  text-transform: capitalize;
}

.cancellation-note {
  color: black;
  font-weight: 600;
  font-size: small;
}

.confirm-layout .right p {
  font-weight: 600;
  font-size: 14px;
  line-height: 21px;
  text-align: center;
}

.confirm-layout .right p a {
  color: #2e8b98;
}
.confirm-layout .right.register .form {
  margin-top: 15px;
}

.confirm-layout .right.register .form-fields {
  width: auto;
  background: rgba(40, 40, 40, 0.4);
  border-radius: 4px;
  margin-bottom: 15px;
  padding: 8px 16px;
  position: relative;
  overflow: hidden;
  border: 1px solid rgba(40, 40, 40, 0.4);
}

.confirm-layout .right.register .form-fields.error {
  border: 1px solid red;
}

.confirm-layout .right.register .form-fields.no-bg {
  background: none;
  padding: 0;
  border: none;
}

.confirm-layout .right.register .form-fields label {
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  display: block;
  margin-bottom: 10px;
}

.confirm-layout .right.register .form-fields input {
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  background: none;
  border: 0 none;
  width: calc(100% - 50px);
  padding: 0 50px 0 0;
  outline: none;
  color: #111111;
}

.confirm-layout .right.register .form-fields input::-webkit-input-placeholder {
  /* Edge */
  color: #111111;
}

.confirm-layout .right.register .form-fields input:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #111111;
}

.confirm-layout .right.register .form-fields input::placeholder {
  color: #111111;
}

.confirm-layout .right.register .form-fields input:hover,
.confirm-layout .right.register .form-fields input:focus {
  outline: none;
}

.confirm-layout .right.register .form-fields .icon {
  position: absolute;
  // bottom: 9px;
  right: 20px;
  width: 20px;
  cursor: pointer;
}

.confirm-layout .right.register .form-fields input.green-btn {
  background: #2e8b98;
  border-radius: 4px;
  border: 1px solid #2e8b98;
  width: 100%;
  padding: 8px 16px;
  color: #ffffff;
  cursor: pointer;
  margin-top: 10px;
}

.confirm-layout .right.register .form-fields input.gray-btn {
  background: #282828;
  border-radius: 4px;
  border: 1px solid #282828;
  width: 100%;
  padding: 8px 16px;
  color: #ffffff;
  cursor: pointer;
  margin-top: 10px;
}

.confirm-layout .right.register .form-fields .left,
.confirm-layout .right.register .form-fields .right {
  float: left;
  width: 50%;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  letter-spacing: 0.03em;
  color: #000000;
  padding: 0;
}

.confirm-layout .right.register .form-fields .right {
  text-align: right;
}

.confirm-layout .right.register .form-fields .left a,
.confirm-layout .right.register .form-fields .right a {
  color: #2e8b98;
}

.confirm-layout .right.register .form-fields input.checkbox {
  width: auto;
  display: inline-block;
  vertical-align: middle;
  margin: 0;
  padding: 0;
}

.confirm-layout .right.register .form-fields.w100 .left {
  width: 100%;
  float: none;
}

.confirm-layout .right.register .form-fields input.gray-btn.mr-5 {
  margin-right: 10px;
  width: calc(100% - 10px);
}

.confirm-layout .right.register .form-fields input.green-btn.ml-5 {
  margin-left: 10px;
  width: calc(100% - 10px);
}

.confirm-layout .right.register .single-line {
  overflow: hidden;
}

.confirm-layout .right.register .single-line .left {
  float: left;
  width: 40% !important;
}

.confirm-layout .right.register .single-line .right {
  float: right;
  width: 40% !important;
}

.bb {
  border-bottom: 1px solid #ccc !important;
}
.splitter {
  border-bottom: 1px solid #969696;
  margin: 10px 0;
}
.form-fields.bb {
  background: #fff !important;
}
.form-fields.bb label {
  font-weight: 600 !important;
  font-size: 14px !important;
}

.mt25 {
  margin-top: 25px;
}

input#fileUpload[type="file"] {
  display: none;
}

label.submit {
  background: #ffffff;
  border: 1px solid rgba(17, 17, 17, 0.6);
  border-radius: 4px;
  padding: 6px 12px;
  display: inline-block;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  color: rgba(17, 17, 17, 0.6);
  // width: auto;
  // margin-top: 20px;
  cursor: pointer;
  width: 25%;
  text-align: center;
}
.traveller-document {
  position: initial !important;
  cursor: auto !important;
  width: 50px;
}
.traveller-document-close {
  width: 20px;
  border-radius: 10px;
  left: 40px;
  top: 24px;
  position: absolute;
  cursor: pointer;
}
.red {
  color: red !important;
}
.coupon-wrapper{
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 14px 0px;
}
.coupon-value-wrapper{
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 14px 0px;
}
.coupon-remove-btn{
  background-color: transparent;
  cursor: pointer;
  border: none;
  padding: 0px;
}

.coupon-apply-btn{
  padding: 12px 8px;
  border-radius: 10px;
  border: 1px solid #72C7C5;
  background-color: #d2f6ff;
  width: 100%;
  margin-top: 6px;
  font-size: 16px;
  font-weight: 500;
  color: #000000;
  text-transform: capitalize;
  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  letter-spacing: 0.6px;
  cursor: pointer;
}

.coupon-value{
  margin-top: 0px;
  font-weight: 600;
  font-size: 16px;
  color: #000;
  display: flex;
  align-items: center;
  gap: 6px;
}

a {
  cursor: pointer;
}

.bookingdetailsDiv table {
  font-family: arial, sans-serif;
  border-collapse: collapse;
  width: 100%;
  margin-top: 15px;
}

.bookingdetailsDiv table th {
  border: 1px solid #dddddd;
  text-align: left;
  padding: 8px;
  color: #5e5959;
  background-color: rgb(210, 246, 255);
}
.bookingdetailsDiv table td {
  border: 1px solid #dddddd;
  text-align: left;
  padding: 8px;
}

.bookingdetailsDiv table tr:nth-child(even) {
  background-color: #f7f6f6;
}

@media screen and (max-width: 425px) {
  label.submit {
    width: calc(100% - 28px);
  }
  .confirm-layout .left .inner-head {
    font-size: 16px;
  }
}

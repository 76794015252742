.discover-international-section {
  margin: 20px 0 50px;
}

.discover-international-section .head {
  font-weight: 500;
  font-size: 25px;
  line-height: 38px;
  margin-bottom: 10px;
}

.discover-international {
  background: url("/assets/images/discover-international.png") no-repeat center
    center / cover;
  padding: 30px 55px;
  overflow: hidden;
  position: relative;
  border-radius: 15px;
}

.discover-international .background-overlay {
  bottom: 0;
  border-radius: 0px;
}

.discover-international .left {
  width: 30%;
  float: left;
}

.discover-international .left .heading,
.discover-international .right .heading {
  font-weight: 400;
  font-size: 20px;
  line-height: 30px;
}

.discover-international .left .offer,
.discover-international .right .offer {
  font-weight: 600;
  font-size: 30px;
  line-height: 45px;
  margin-top: 5px;
}

.discover-international .left p,
.discover-international .right p {
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  margin-bottom: 20px;
}

.discover-international .left .green-btn-input a,
.discover-international .right .green-btn-input a {
  background: #2e8b98;
  border-radius: 6px;
  font-weight: 500;
  font-size: 12px;
  line-height: 24px;
  text-transform: uppercase;
  // text-shadow: 0px 4px 4px rgb(0 0 0 / 25%);
  padding: 8px 75px;
  display: inline-block;
  color: #ffffff;
}

.discover-international .right {
  width: 50%;
  float: right;
  // background: url("") no-repeat center center / 100%;
  // padding: 35px 65px;
  // text-align: right;
  // border-radius: 10px;
}

.discover-international .left .discover-blk {
  background: none !important;
  // padding: 0;
  text-align: left;
}

.discover-international .left .discover-blk .discover-container,
.discover-international .right .discover-blk .discover-container {
  position: absolute;
  top: 50%;
  left: 50px;
  transform: translate(0px, -50%);
}

.discover-international .right .discover-blk .discover-container {
  right: 50px;
  left: inherit;
}

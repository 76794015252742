.payment-section-blk {
    max-width: 735px;
    margin: 0px auto;
    color: #ffffff;
    padding: 40px 0;
}

.payment-section-blk .img {
    width: 60px;
    height: 60px;
    margin: 0 auto;
    margin-bottom: 15px;
}

.payment-section-blk .head {
    font-weight: 600;
    font-size: 40px;
    line-height: 70px;
    text-align: center;
    color: #FFFFFF;
}

.payment-section-blk .content {
    max-width: 600px;
    margin: 40px auto 0;
    text-align: center;
}

.payment-section-blk .content p {
    margin: 0 auto 50px;
    text-align: center;
    max-width: 400px;
}

.payment-section-blk .content .date-location {
    overflow: hidden;
    background: rgba(217, 217, 217, 0.1);
    border-radius: 10px;
    margin: 0 0 50px 0;
}

.payment-section-blk .content .date-location .image {
    width: 300px;
    float: left;
}

.payment-section-blk .content .date-location .image img {
    min-height: 205px;
    max-height: 205px;
    width: 100%;
    display: block;
}

.payment-section-blk .content .date-location .detail {
    float: left;
    width: calc(300px - 30px);
    padding: 5px 15px;
}

.payment-section-blk .content .date-location .detail .head {
    font-weight: 600;
    font-size: 18px;
    line-height: 30px;
    text-align: left;
}

.payment-section-blk .content .date-location .detail .des {
    font-weight: 400;
    font-size: 13px;
    line-height: 21px;
}

.payment-section-blk .content .date-location .detail .des span {
    display: block;
    font-weight: 600;
}

.payment-section-blk .content .date-location .detail p {
    margin: 0 0 10px 0;
    text-align: left;
}

.payment-section-blk .content .green-btn {
    background: #2E8B98;
    border-radius: 6px;
    font-weight: 500;
    font-size: 12px;
    line-height: 24px;
    text-transform: uppercase;
    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    padding: 8px 75px;
    display: inline-block;
    color: #ffffff;
    cursor: pointer;
}
span {
    display: inline-block;
    text-align: left;
}

span.name {
    font-weight: 400;
    font-size: 20px;
    line-height: 30px;
    position: absolute;
    bottom: 55px;
    width: auto;
    display: inline-block;
    left: 0;
    padding: 5px 10px;
    color: white
}

span.mobile-name {
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    position: absolute;
    bottom: 8px;
    width: auto;
    display: inline-block;
    left: 0;
    text-align: center;
    // padding: 5px 10px;
    width: 100%;
    display: flex;
    justify-content: center;
    color: white
}

span.image {
    position: relative;
}

span.image .tag {
    position: absolute;
    top: 25px;
    width: auto;
    display: inline-block;
    left: 0;
    padding: 5px 20px 5px 10px;
    font-weight: 500;
    font-size: 12px;
    line-height: 18px;
    // text-transform: uppercase;
    // background: rgba(21, 21, 21, 0.8);
    color: #ffffff;
    // box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.35)
    background: url("/assets/images/ribbon.svg") no-repeat right center / cover;

}

span.head {
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    margin-top: 15px;
    display: block;
}

span.days {
    font-weight: 600;
    font-size: 13px;
    line-height: 20px;
    margin-top: 5px;
    display: block;
}

span.price {
    font-weight: 600;
    font-size: 16px;
    line-height: 18px;
    color: #67E1F2;
    display: block;
    margin-top: 5px;
}

span.price span.offer {
    color: #ffffff;
    margin-right: 10px;
    text-decoration: line-through;
}

.card-image img {
    border-radius: 15px;
    display: block !important;
}

.loading {
    filter: blur(0px);
    clip-path: inset(0);
  }
  .loaded {
    filter: blur(0px);
    transition: filter 0.5s linear;
  }

.viewall-card {
    padding-top: 10px;
    padding-left: 10px;
    padding-right: 10px;
    padding-bottom: 3px;
}
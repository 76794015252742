.details-page {
    background: #151515;
}

.search-blk {
    max-width: 445px;
    margin: 30px auto 40px;
    overflow: hidden;
}

.search-blk input {
    background: #1F1F1F;
    border: 1px solid #1F1F1F;
    box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.16);
    border-radius: 95px 0 0 95px;
    padding: 13px 20px;
    font-weight: 500;
    font-size: 13px;
    line-height: 20px;
    color: #515151;
    float: left;
    width: 295px;
}

.search-blk span {
    background: #2F2F2F;
    border: 1px solid #2F2F2F;
    border-radius: 0 95px 95px 0;
    font-weight: 500;
    font-size: 13px;
    line-height: 20px;
    color: #67E1F2;
    padding: 13px 30px;
    float: left;
    cursor: pointer;
}

.faq-tabs-blk {
    overflow: hidden;
    text-align: center;
    margin-bottom: 30px;
}

.faq-tabs-blk ul {
    list-style: none;
    margin: 0;
    padding: 0;
}

.faq-tabs-blk ul li {
    display: inline-block;
    vertical-align: middle;
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    max-width: 195px;
    margin: 0 10px;
}

.faq-tabs-blk ul li span {
    color: #FFFFFF;
    padding: 20px 40px;
    background: #1B1B1B;
    border-radius: 10px;
    display: block;
    border: 1px solid #1B1B1B;
}

.faq-tabs-blk ul li span.active {
    background: #082D32;
    border: 1px solid #67E1F2;
}

.faq-tabs-head {
    font-weight: 500;
    font-size: 28px;
    line-height: 42px;
    color: #FFFFFF;
    margin: 40px 0;
}

.faq-acc {
    overflow: hidden;
}

.faq-acc .faq-acc-head {
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    padding: 15px 0;
    border-bottom: 1px solid #474747;
    position: relative;
}

.faq-acc .faq-acc-head span {
    background: url("/assets/images/faq-plus.png") no-repeat center center / cover;
    width: 22px;
    height: 22px;
    position: absolute;
    right: 10px;
    cursor: pointer;
}

.faq-acc .faq-acc-head.active {
    border: 0 none;
}

.faq-acc .faq-acc-head.active span {
    background: url("/assets/images/faq-minus.png") no-repeat center center / cover;
}

.faq-acc-content {
    padding: 15px 0;
}

.faq-acc-content.show {
    display: block;
}

.faq-acc-content.hide {
    display: none;
}

.faq-blk {
    max-width: 735px;
    margin: 0 auto;
}
.deals-section {
    position: relative;
    margin: 0px 0 50px;
}

.deals-section .heading {
    font-weight: 500;
    font-size: 25px;
    line-height: 38px;
    margin-bottom: 10px;
    position: relative;
}

.deals-section .heading span {
    color: #67E1F2;
}

.deals-section .heading span.view-all {
    position: absolute;
    right: 155px;
    top: 6px;
    font-weight: 400;
    font-size: 20px;
    line-height: 30px;
}

.deals-section .heading span.view-all a {
    color: #3DD2E7;
}

.deals-gallery {
    position: relative;
    padding: 0 10px;
    text-align: center;
}

.deals-gallery ul {
    list-style: none;
    margin: 0;
    padding: 0;
}

.deals-gallery ul li {
    display: inline-block;
    vertical-align: top;
    margin: 0 10px;
    width: 240px;
}

.deals-gallery.discover ul li {
    width: 31.8%;
}

.deals-gallery.discover ul li:first-child {
    margin-left: 0;
}

.deals-gallery.discover ul li:last-child {
    margin-right: 0;
}

.deals-gallery.discover {
    text-align: left;
    padding: 0;
}